/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import {
  CREATE_QUOTES,
  HEADER_TABLE_QUOTES,
  SELECT_FROM_BUY,
  CREATE_QUOTES_ADMIN,
  VALIDATE_DAY,
} from "../constants";
import { fillDefaultValues } from "../helpers/filler";
import { postData, getData } from "../helpers/request";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import _ from "lodash";
import "../css/component.css";
import ComponentPdf from "../components/componentGeneratePdf";
const EMPTY_USER = fillDefaultValues(CREATE_QUOTES);

const ComponentCreateQuotes = ({ getQuote, getIdQuotes = (e) => {}, newServiceOin = null }) => {
  const [timerInput, setTimerInput] = useState(null);
  const [newQuote, setNewQuote] = useState(EMPTY_USER);
  const [newService, setService] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [selectInput, setSelectInput] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [countAll, setCountAll] = useState("");
  const [iva, setIva] = useState("");
  const [total, setTotal] = useState("");
  const ivaValue = 0.19;
  const [btnSaveText, setSaveText] = useState("Guardar");

  const editQuote = async () => {
    if (getQuote) {
      let quoteGet = {
        idQuotes: getQuote.id,
        clientId: getQuote.clientI,
        document: getQuote.document,
        items: getQuote.item,
        selectFormBuy: getQuote.wayPa,
        validateDay: getQuote.offerValidit,
        nameSignature: getQuote.signatur,
        charges: getQuote.signaturePositio,
      };
      searchCustomer(quoteGet);
      loadItemsTable(quoteGet, newServiceOin);
    }
  };

  // traemos los datos del usuario para la cotización
  const searchCustomer = (value) => {
    if (value) {
      clearTimeout(timerInput);
      const newTimer = setTimeout(() => {
        getData({
          route: `getClientByDocument&document=${value.document}`,
        }).then((response) => {
          if (response.success) {
            let dataR = response.data;
            dataR.clientId = response.data.id;
            delete dataR.id;
            if(dataR.documentType == "Nit"){
              dataR.nameOrigin = dataR.name;
              dataR.name = dataR.contactOne;
              dataR.phone = dataR.contactOnePhone;
            }
            setNewQuote({
              ...value,
              ...dataR,
            });
          }
        });
      }, 1000);
      setTimerInput(newTimer);
    }
  };

  const loadItemsTable = (value, newServiceOin = null) => {
    let newServiceLocal = newService;
    if(newServiceOin != null){
      newServiceLocal = newServiceOin;
    }
    const itemss = JSON.parse(value.items);
    let i = 1;
    let newDataT = [];
    itemss.forEach(async (element) => {
      let itemmm = _.find(newServiceLocal, { id: element.id });
      const itemNeww = {
        id: i++,
        description: itemmm.name,
        descriptionExtra: element.descriptionExtra,
        cantVR: element.amount,
        cantUnit: element.price,
        total: element.amount * element.price,
        idService: itemmm.id,
      };
      newDataT.push(itemNeww);
    });
    calculateTableData(newDataT);
  };

  const handleChangeUser = (event) => {
    var dataQ = {
      ...newQuote,
      [event.target.name]: event.target.value,
    };

    if (event.target.name === "document") {
      searchCustomer(dataQ);
    }
    setNewQuote(dataQ);
    if (!event.target.value) {
      setNewQuote(EMPTY_USER);
    }
  };

  const onChangeSelectItem = (event) => {
    const idItem = parseInt(event.target.value);
    setSelectItem(_.find(newService, { id: idItem }));
  };

  const handleChangeCant = async (event) => {
    const value = parseInt(event.target.value);
    setSelectInput(value);
  };

  const renderUserInputType = (field, selectDefault = "0") => {
    switch (field.type) {
      case "select":
        return (
          <Form.Select 
            onChange={handleChangeUser} 
            name={field.name}
            value={selectDefault}
          >
            <option key={0} value={0}>
              Selecciona
            </option>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={newQuote[field.name]}
            onChange={handleChangeUser}
            disabled={field.disable === 1}
          />
        );
    }
  };

  const keyPress = () => {
    if (_.isEmpty(selectItem)) {
      return;
    }
    const itemNeww = {
      id: _.size(dataTable) + 1,
      description: selectItem.name,
      cantVR: 1,
      cantUnit: selectItem.price,
      total: 1 * selectItem.price,
      idService: selectItem.id,
    };
    const newDataT = [...dataTable, itemNeww];
    calculateTableData(newDataT);
  };

  // guarda los nuevos items que quieran crear
  const handleCategoryChange = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Agregar servicio",
      html: `
        <input id="name" class="swal2-input" type="text" placeholder="Producto">
        <input id="price" class="swal2-input" type="number" placeholder="valor unitario">
      `,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("name").value,
          document.getElementById("price").value,
        ];
      },
    });
    if (formValues) {
      let saveItems = {
        name: formValues[0],
        price: formValues[1],
      };
      postData({
        route: `createService`,
        data: saveItems,
      }).then((response) => {
        if (response.success) {
          Swal.fire({
            position: "end",
            icon: "success",
            title: "Servicio guardado correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
          getServices();
        }
      });
    }
  };

  // para guardar la cotización con todos los datos
  const handleSubmit = () => {
    let items = [];
    for (const key in dataTable) {
      items.push({
        id: dataTable[key].idService,
        amount: dataTable[key].cantVR,
        price: dataTable[key].cantUnit,
        descriptionExtra: dataTable[key].descriptionExtra,
      });
    }
    let saveData = {
      clientId: newQuote.clientId,
      items: JSON.stringify(items),
      wayPay: newQuote.selectFormBuy,
      offerValidity: newQuote.validateDay,
      signature: newQuote.nameSignature,
      signaturePosition: newQuote.charges,
    };
    postData({
      route: `createQuotation`,
      data: saveData,
    }).then((response) => {
      if (response.success) {
        var dataQ = {
          ...newQuote,
          idQuotes: response.data,
        };
        getIdQuotes(response.data);
        setNewQuote(dataQ)
        setShowButton(true);
        setSaveText("Crear");
        Swal.fire({
          position: "end",
          icon: "success",
          title: `Cotización guardada correctamente con el id: ${response.data}`,
          showConfirmButton: true,
        });
      }else{
        Swal.fire({
          position: "end",
          icon: "error",
          title: response.error.message,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    });
  };
  const EditData = () => {
    let items = [];
    for (const key in dataTable) {
      items.push({
        id: dataTable[key].idService,
        amount: dataTable[key].cantVR,
        price: dataTable[key].cantUnit,
        descriptionExtra: dataTable[key].descriptionExtra,
      });
    }
    let saveData = {
      id: newQuote.idQuotes,
      clientId: newQuote.clientId,
      items: JSON.stringify(items),
      wayPay: newQuote.selectFormBuy,
      offerValidity: newQuote.validateDay,
      signature: newQuote.nameSignature,
      signaturePosition: newQuote.charges,
    };
    postData({
      route: `updateQuotation`,
      data: saveData,
    }).then((response) => {
      if (response.success) {
        Swal.fire({
          position: "end",
          icon: "success",
          title: "Cotización guardada correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }else{
        Swal.fire({
          position: "end",
          icon: "error",
          title: response.error.message,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    });
  };

  const deleteRow = (value) => {
    var newDataT = _.cloneDeep(dataTable);
    _.remove(newDataT, { id: value });
    for (let i = 0; i < _.size(newDataT); i++) {
      newDataT[i].id = i + 1;
    }
    calculateTableData(newDataT);
  };

  const getServices = () => {
    getData({
      route: `getService`,
    }).then((response) => {
      if (response.success) {
        setService(response.data);
      }
    });
  };

  const onChangePrice = (event, valItem) => {
    let dataTableClone = _.cloneDeep(dataTable);
    const priceNew = parseInt(event.target.value);
    const index = _.findIndex(dataTableClone, { "id": valItem.id });
    dataTableClone[index] = { ...dataTableClone[index], ...{cantUnit: priceNew, total: valItem.cantVR * priceNew } };
    calculateTableData(dataTableClone);
  }

  const onChangeAmount = (event, valItem) => {
    let dataTableClone = _.cloneDeep(dataTable);
    const amountNew = parseInt(event.target.value);
    const index = _.findIndex(dataTableClone, { "id": valItem.id });
    dataTableClone[index] = { ...dataTableClone[index], ...{cantVR: amountNew, total: amountNew * valItem.cantUnit } };
    calculateTableData(dataTableClone);
  }

  const onChangeDescriptionExtra = (event, valItem) => {
    let dataTableClone = _.cloneDeep(dataTable);
    const index = _.findIndex(dataTableClone, { "id": valItem.id });
    dataTableClone[index] = { ...dataTableClone[index], ...{descriptionExtra: event.target.value } };
    setDataTable(dataTableClone);
  }

  const calculateTableData = (dataTableClone) => {
    setDataTable(dataTableClone);
    const supTotal = _.sumBy(dataTableClone, function (o) {
      return o.total;
    });
    setCountAll(supTotal);
    const ivaT = supTotal * ivaValue;
    setIva(ivaT);
    setTotal(ivaT + supTotal);
  }

  useEffect(() => {
    try {
      getServices();
      if (getQuote) {
        editQuote();
        setShowButton(true);
        setSaveText("Crear");
      } else {
        setNewQuote(
          fillDefaultValues([
            ...CREATE_QUOTES,
            ...CREATE_QUOTES_ADMIN,
            ...VALIDATE_DAY,
          ])
        );
        setDataTable([]);
        setCountAll("");
        setIva("");
        setTotal("");
        setShowButton(false);
        setSaveText("Guardar");
      }
    } catch (error) {
      console.log(error);
    }
  }, [getQuote]);

  return (
    <div className="row g-3">
      {CREATE_QUOTES.map((field, index) => (
        <Form.Group key={index} className={field.stile}>
          <Form.Label>{field.displayName}</Form.Label>
          {renderUserInputType(field)}
        </Form.Group>
      ))}
      <div className="col-12 delimiter-line"></div>
      <div className="col-12">
        <Button onClick={handleCategoryChange}>
          Crear&nbsp;
          <i className="fa-solid fa-file-circle-plus"></i>
        </Button>
      </div>
      <Table bordered hover className="table">
        <thead>
          <tr>
            {HEADER_TABLE_QUOTES.map((field, index) => (
              <th key={index} colspan={field.name == "description" ? 3 :1}>{field.displayName}</th>
            ))}
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {dataTable.map((value, index) => (
            <tr key={index}>
              <td>{value.id}</td>
              <td colspan="3">{value.description}</td>
              <td>
                <Form.Control
                  type="text"
                  value={value.descriptionExtra}
                  onChange={(e) => onChangeDescriptionExtra(e, value)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={value.cantVR}
                  onChange={(e) => onChangeAmount(e, value)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={value.cantUnit}
                  onChange={(e) => onChangePrice(e, value)}
                />
              </td>
              <td>{value.total}</td>
              <td>
                <Button variant="danger" onClick={() => deleteRow(value.id)}>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
              </td>
            </tr>
          ))}
          <tr>
            <td colspan="9">
              <div className="row">
                <div className="col-8">
                  <Form.Select onChange={onChangeSelectItem} name="name">
                    <option key={0} value={0}>
                      Selecciona un servicio
                    </option>
                    {newService.map((field, index) => (
                      <option key={index} value={field.id}>
                        {field.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-4">
                  <Button onClick={keyPress}>
                    Agregar&nbsp;
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="7">SUBTOTAL</td>
            <td>
              <input
                type="number"
                className="form-control"
                placeholder={countAll}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td colspan="7">IVA (19%)</td>
            <td>
              <input
                type="number"
                className="form-control"
                placeholder={iva}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td colspan="7">TOTAL</td>
            <td>
              <input
                type="number"
                className="form-control"
                placeholder={total}
                disabled
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="row g-3">
        <div className="col-md-4">
          <h5>Forma de pago</h5>
        </div>
        <div className="col-md-6">
          {SELECT_FROM_BUY.map((field, index) => (
            <Form.Group key={index} className="mb-2">
              {renderUserInputType(field, newQuote.selectFormBuy)}
            </Form.Group>
          ))}
        </div>
        <br></br>
        Consignar a nombre de IZAJES E INGENIERIA DE COLOMBIA SAS Nit:
        901.444.810–3 a la cuenta de ahorros No 39500001489 Banco BANCOLOMBIA.
        <div className="col-md-6">
          <h5>Validez de oferta</h5>
        </div>
        <div className="col-md-4">
          {VALIDATE_DAY.map((field, index) => (
            <Form.Group key={index} className="mb-2">
              {renderUserInputType(field)}
            </Form.Group>
          ))}
        </div>
        <div className="text-center">
          <h4>Términos y condiciones</h4>
        </div>
        <div className="d-flex p-2">
          {" "}
          Nota 1: Si se requiere realizar inspección complementaria, el costo
          corresponde al valor de los viáticos por la prestación de este
          servicio.
        </div>
        <div className="d-flex p-2">
          Nota 2: En caso de requerir presentar nuevamente un examen, el costo
          corresponde al valor de los viáticos por la prestación de este
          servicio.
        </div>
        <div className="d-flex p-2">
          Nota 3: La aceptación del servicio implica que el cliente conoce,
          entiende y acepta las condiciones contenidas en el alcance y en las
          reglas del servicio.
        </div>
        <div className="d-flex p-2">
          Anexo: Reglas del servicio de inspección de equipos.
          <a
            href="https://izaincol.com.co/files/reglas_del_servicio_de_certificacion_equipos.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Link
          </a>
        </div>
        <div className="d-flex p-2">
          Reglas del servicio de certificación personas.
          <a
            href="https://izaincol.com.co/files/reglas_del_servicio_de_certificacion.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Link
          </a>
        </div>
      </div>

      <div className="col-md-4">
        {CREATE_QUOTES_ADMIN.map((field, index) => (
          <Form.Group key={index} className={field.stile}>
            <Form.Label>{field.displayName}</Form.Label>
            {renderUserInputType(field)}
          </Form.Group>
        ))}
      </div>
      <div className="row justify-content-end">
        <div className="col-4">
          {showButton && (
            <button onClick={EditData} className="btn btn-primary m-lg-3">
              Actualizar <i className="fa fa-pencil"></i>
            </button>
          )}
          <button onClick={handleSubmit} className="btn btn-primary m-lg-3">
            {btnSaveText} <i className="fa-solid fa-floppy-disk"></i>
          </button>
          <div>
            <ComponentPdf
              dataPdf={newQuote}
              contentTable={dataTable}
              iva={iva}
              total={total}
              subTotal={countAll}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentCreateQuotes;
