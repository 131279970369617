import React, { useState } from 'react';
import Swal from 'sweetalert2';

const TheoreticalEvaluation = () => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleAcceptTerms = () => {
    setAcceptedTerms(true);
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSf9HmEfUyMWWdMtlmb2vkXxhxINd_izjACW1_s4i7rcRbjK6A/viewform';
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
      <div className="card">
        <div className="card-body text-center">
          <h1 className="mb-4">Términos y Condiciones</h1>
        </div>
        <div className="card-body">
          <span className="mb-4">Al seleccionar la opción <strong>ACEPTAR</strong>, usted declara de manera expresa e inequívoca que conoce, comprende y acepta las reglas del servicio de certificación y contrato de certificación de personas.</span>
          <br/><br/>
          <span className="mb-4">De tal manera, se reconoce que el incumplimiento de este contrato tiene consecuencias legales y permite la ejecución legal de todas las disposiciones establecidas en el mismo.</span>
        </div>
        <div className="card-body text-center">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <input type="checkbox" className="form-check-input mr-2" id="acceptTerms" checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
            <label className="form-check-label" htmlFor="acceptTerms">Acepto los términos y condiciones</label>
          </div>
          <button className="btn btn-primary mx-auto" onClick={handleAcceptTerms} disabled={!acceptedTerms}>Aceptar</button>
        </div>
      </div>
    </div>
  );
};

export default TheoreticalEvaluation;
