import Loader from "../components/Loader";
import { useState, useEffect } from "react";
import ComponentCreateQuotes from "../components/componentCreateQuotes";
import { getData } from "../helpers/request";
import Swal from "sweetalert2";
import { Modal, Button, Table } from "react-bootstrap";
import { HEADER_GET_QUOTES } from "../constants";

const Quotes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [quotes, getQuotes] = useState([]);
  const [allQuotes, getAllQuotes] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [pages, setPages] = useState({
    paginado: 0,
  });
  
  const [timerInput, setTimerInput] = useState(null);

  const handleShow = () => {
    getDataTable();
    setShow(true);
  };

  const getDataTable = () => {

    getData({
      route: `listQuotation&page=${pages.paginado}`,
    })
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          getAllQuotes(response.data)
        } else {
          Swal.fire({
            title: "No se encontraron datos de este registro",
            icon: "warning",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    clearTimeout(timerInput);
    const newTimer = setTimeout(() => {
      searchCertificates(value);
    }, 1000);
    setTimerInput(newTimer);
  };

  const searchCertificates = (value) => {
    if (value) {
      clearTimeout(timerInput);
      const newTimer = setTimeout(() => {
        getData({
          route: `getQuotation&id=${value}`,
        })
          .then((response) => {
            setIsLoading(false);
            if (response.success) {
              getQuotes(response.data);
            } else {
              Swal.fire({
                title: "No se encontraron datos de este registro",
                icon: "warning",
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
      setTimerInput(newTimer);
    } else {
      getQuotes();
    }
  };

  useEffect(() => {
      getDataTable();
  }, [pages]);

  return (
    <div className="dashboard">
      {isLoading && <Loader />}
      <div className="shadow rounded bg-white dashboard-container dashboard-inner-container">
        <div className="dashboard-header mb-5">
          <h2>COTIZACIONES</h2>
          <Button onClick={handleShow}>
            Ver Cotizaciones&nbsp;
            <i className="fa fa-search" aria-hidden="true"></i>
          </Button>
          <div className="row justify-content-end">
            <h6>BUSCAR COTIZACIÓN POR NUMERO </h6>
            <div className="input-group mb-3">
              <span
                className="input-group-text"
                onClick={() => searchCertificates(filterValue)}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
              <input
                type="text"
                className="form-control"
                value={filterValue}
                onChange={handleFilterChange}
              />
            </div>
          </div>
        </div>
        <div className="certificates">
          <div className="dashboard-container">
            <ComponentCreateQuotes getQuote={quotes} />
          </div>

          <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header closeButton>
              <Modal.Title>Cotizaciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover className="table">
                <thead>
                  <tr>
                    {HEADER_GET_QUOTES.map((field, index) => (
                      <th key={index}>{field.displayName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                {allQuotes.map((value, index) => (
                  <tr key={index}>
                    <td>{value?.id}</td>
                    <td>{value?.nameClient}</td>
                    <td>{value?.createdAt}</td>
                    <td>{value?.signatur}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <div className="paginator">
              <Button
                onClick={() => setPages({ ...pages, paginado: --pages.paginado })}
                disabled={pages.paginado === 0}
                variant="warning"
              >
                {"<"}
              </Button>
              <p>Página {pages.paginado + 1}</p>
              <Button
                onClick={() => setPages({ ...pages, paginado: ++pages.paginado })}
                variant="warning"
              >
                {">"}
              </Button>
            </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Quotes;
