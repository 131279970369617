import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { CREATE_CUSTOMER, CREATE_CUSTOMER_JURIDIC } from "../constants";
import { fillDefaultValues } from "../helpers/filler";
import { postData, getData, getDepartments } from "../helpers/request";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
const _ = require('lodash');

const EMPTY_USER = fillDefaultValues(CREATE_CUSTOMER);
const EMPTY_USER_JURIDIC = fillDefaultValues(CREATE_CUSTOMER_JURIDIC);

const FormUsers = ({ typeForm }) => {
  const [newUser, setNewUser] = useState(EMPTY_USER);
  const [newUserJuridic, setNewUserJuridic] = useState(EMPTY_USER_JURIDIC);
  const [departments, setDepartments] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [dataGov, setDataGov] = useState([]);
  const [department, setDepartment] = useState("");
  const [city, setCity] = useState("");

  // trae clientes registrados
  const searchCustomer = (value) => {
    if (value) {
      getData({
        route: `getClientByDocument&document=${value}`,
      }).then((response) => {
        if (response.success) {
          setNewUser(response.data);
          setNewUserJuridic(response.data);
          if(response.data.cityDepartment){
            const parts = _.split(response.data.cityDepartment, '/');
            setDepartment(parts[0]);
            const dataMunicipio = dataGov.filter((item) =>
              item.departamento.includes(parts[0])
            );
            setMunicipios(dataMunicipio);
            setCity(parts[1]);
          }
        }
      });
    }
  };
  const handleChangeUser = (event) => {
    if (event.target.name === "document") {
      searchCustomer(event.target.value);
    }
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeUserJuridic = (event) => {
    if (event.target.name === "document") {
      searchCustomer(event.target.value);
    }
    setNewUserJuridic({
      ...newUserJuridic,
      [event.target.name]: event.target.value,
    });
  };

  const renderUserInputType = (field, selectDefault = "0") => {
    switch (field.type) {
      case "select":
        return (
          <Form.Select onChange={handleChangeUser} name={field.name} value={selectDefault}>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={newUser[field.name]}
            onChange={handleChangeUser}
          />
        );
    }
  };

  const renderUserJuridicInputType = (field) => {
    return (
      <Form.Control
        type={field.type}
        name={field.name}
        value={newUserJuridic[field.name]}
        onChange={handleChangeUserJuridic}
      />
    );
  };

  const saveData = () => {
    newUser.cityDepartment = department + "/" + city;
    if (newUser) {
      postData({
        route: "createNaturalClient",
        data: newUser,
      }).then((response) => {
        if (response.success) {
          Swal.fire({
            position: "position-absolute bottom-0 end-0",
            icon: "success",
            title: "Guardado correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "end",
            icon: "error",
            title: response.error.message,
            showConfirmButton: false,
            timer: 3500,
          });
        }
      });
    }
  };

  const handleSubmitJuridic = (event) => {
    event.preventDefault();
    newUserJuridic.cityDepartment = department + "/" + city;
    if (newUserJuridic) {
      postData({
        route: "createClientLegal",
        data: newUserJuridic,
      }).then((response) => {
        if (response.success) {
          Swal.fire({
            position: "position-absolute bottom-0 end-0",
            icon: "success",
            title: "Guardado correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "end",
            icon: "error",
            title: response.error.message,
            showConfirmButton: false,
            timer: 3500,
          });
        }
      });
    }
  };

  const onChangeSelectDepartments = (event) => {
    setDepartment(event.target.value);
    const dataMunicipio = dataGov.filter((item) =>
      item.departamento.includes(event.target.value)
    );
    setMunicipios(dataMunicipio);
  };
  const onChangeSelectMunicipios = (event) => {
    setCity(event.target.value);
  };

  const dataDep = () => {
    getDepartments().then((response) => {
      setDataGov(response);
      const uniqueDepartments = new Set(
        response.map((item) => item.departamento)
      );
      setDepartments(Array.from(uniqueDepartments));
    });
  };

  useEffect(() => {
    dataDep();
  }, []);

  if (typeForm === "1") {
    return (
      <Form className="row g-3">
        {CREATE_CUSTOMER.map((field, index) => (
          <Form.Group key={index} className="col-md-4">
            <Form.Label>{field.displayName}</Form.Label>
            {renderUserInputType(field, newUser[field.name])}
          </Form.Group>
        ))}
        <div className="col-md-6 mt-2.5">
        <Form.Label>Departamento</Form.Label>
          <Form.Select onChange={onChangeSelectDepartments} value={department} name="name">
            <option key={0} value={0}>
              Seleccione un Departamento
            </option>
            {departments.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </Form.Select>
          </div>
          <div className="col-md-4">
          <Form.Label>Municipio</Form.Label>
          <Form.Select onChange={onChangeSelectMunicipios} value={city} name="name">
            <option key={0} value={0}>
              Seleccione un Municipio
            </option>
            {municipios.map((field, index) => (
              <option key={index} value={field.municipio}>
                {field.municipio}
              </option>
            ))}
          </Form.Select>
          </div>
        
        <Button onClick={saveData} className="btn btn-primary">
          Guardar
        </Button>
      </Form>
    );
  } else if (typeForm === "2") {
    return (
      <Form className="row g-3">
        {CREATE_CUSTOMER_JURIDIC.map((field, index) => (
          <Form.Group key={index} className="col-md-4">
            <Form.Label>{field.displayName}</Form.Label>
            {renderUserJuridicInputType(field)}
          </Form.Group>
        ))}
        <div className="col-md-4 mt-2.5">
        <Form.Label>Departamento</Form.Label>
          <Form.Select onChange={onChangeSelectDepartments} value={department} name="name">
            <option key={0} value={0}>
              Seleccione un Departamento
            </option>
            {departments.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </Form.Select>
          </div>
          <div className="col-md-4">
          <Form.Label>Municipio</Form.Label>
          <Form.Select onChange={onChangeSelectMunicipios} value={city} name="name">
            <option key={0} value={0}>
              Seleccione un Municipio
            </option>
            {municipios.map((field, index) => (
              <option key={index} value={field.municipio}>
                {field.municipio}
              </option>
            ))}
          </Form.Select>
          </div>
        <button onClick={handleSubmitJuridic} className="btn btn-primary">
          Guardar
        </button>
      </Form>
    );
  }
};

export default FormUsers;
