import Logo from "../img/logo.png";
import Cookies from "js-cookie";
import "../css/tapMenu.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Dashboard from "./Dashboard/Dashboard";
import Customer from "./createCustomer";
import Quotes from "./createQuotes";
import Ocp from "../views/createOcp";
import Oin from "../views/createOin";
import OrderJob from "../views/orderJob";
import "@fortawesome/fontawesome-free/css/all.min.css";

const tapMenu = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigation = useNavigate();
  const logOut = () => {
    Cookies.remove("IZAIIIIII");
    navigation("/");
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [activeTab, setActiveTab] = useState(0); // Estado para mantener la pestaña activa

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="dashboard">
      <div className="shadow rounded bg-white dashboard-container dashboard-inner-container">
        <div className="dashboard-header mb-5">
          <div className="col-md-3">
            <img src={Logo} />
          </div>
          <div className="menu-container">
            <div className="tab-menu">
              <button
                className={activeTab === 0 ? "active" : ""}
                onClick={() => handleTabClick(0)}
              >
                Certificados
              </button>
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => handleTabClick(1)}
              >
                Cotizaciones
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => handleTabClick(2)}
              >
                OCP
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => handleTabClick(3)}
              >
                OIN
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => handleTabClick(4)}
              >
                Cliente
              </button>
              <button
                className={activeTab === 5 ? "active" : ""}
                onClick={() => handleTabClick(5)}
              >
                OT
              </button>
              <button style={{ width: "60px" }} onClick={() => logOut()}>
                {/* <i class="fa-solid fa-right-from-bracket"></i> */}
                <i className="fa-solid fa-right-from-bracket"></i>
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="tab-content">
            {activeTab === 0 && (
              <TabContent>
                <Dashboard />
              </TabContent>
            )}
            {activeTab === 1 && (
              <TabContent>
                <Quotes />
              </TabContent>
            )}
            {activeTab === 2 && (
              <TabContent>
                <Ocp />
              </TabContent>
            )}
            {activeTab === 3 && (
              <TabContent>
                <Oin />
              </TabContent>
            )}
            {activeTab === 4 && (
              <TabContent>
                <Customer />
              </TabContent>
            )}
            {activeTab === 5 && (
              <TabContent>
                <OrderJob />
              </TabContent>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function TabContent({ children }) {
    return <div className="tab-content">{children}</div>;
  }
};
export default tapMenu;
