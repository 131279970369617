import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { TABLE_ORDER_JOB } from "../constants";
import { getData, postData } from "../helpers/request";
import Swal from "sweetalert2";

const OrderJob = () => {
  const [allJobs, getAllJobs] = useState([]);
  const [pages, setPages] = useState({
    paginado: 0,
  });

  const getDataTable = () => {
    getData({
      route: `getListWorkOrder&page=${pages.paginado}`,
    }).then((response) => {
      if (response.success) {
        getAllJobs(response.data);
      } else {
        Swal.fire({
          title: "No se encontraron datos de este registro",
          icon: "warning",
        });
      }
    });
  };

  const changeState = (value) => {
    if(value.state === "FINALIZADO"){
      return
    }
    value.state = "FINALIZADO"
    postData({
      route: `updatedWorkOrder`,
      data: value,
    }).then((response) => {
      if (response.success) {
        Swal.fire({
          position: "end",
          icon: "success",
          title: `Actualizado con éxito`,
          showConfirmButton: false,
          timer: 3500,
        });
        getDataTable();
      } else {
        Swal.fire({
          title: "No se encontraron datos de este registro",
          icon: "warning",
        });
      }
    });
  }

  useEffect(() => {
    getDataTable();
  }, [pages]);

  //   templete
  return (
    <div className="dashboard">
      <div className="shadow rounded bg-white dashboard-container dashboard-inner-container">
        
          <div className="certificates">
            <div className="dashboard-container">
              <Table bordered hover className="table">
                <thead>
                  <tr>
                    {TABLE_ORDER_JOB.map((field, index) => (
                      <th key={index}>{field.displayName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody >
                  {allJobs.map((value, index) => (
                    <tr key={index}>
                      <td>{value?.id}</td>
                      <td>{value?.name}</td>
                      <td>{value?.inspector}</td>
                      <td>{value?.nameClient}</td>
                      <td>{value?.documentClient}</td>
                      <td>{value?.idOin}</td>
                      <td ><Button variant={value?.state === "PENDIENTE" ? "warning"  : "success"} onClick={() => changeState(value)}>{value?.state}</Button></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="paginator">
                <Button
                  onClick={() =>
                    setPages({ ...pages, paginado: --pages.paginado })
                  }
                  disabled={pages.paginado === 0}
                  variant="warning"
                >
                  {"<"}
                </Button>
                <p>Página {pages.paginado + 1}</p>
                <Button
                  onClick={() =>
                    setPages({ ...pages, paginado: ++pages.paginado })
                  }
                  variant="warning"
                >
                  {">"}
                </Button>
              </div>
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default OrderJob;
