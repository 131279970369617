import jsPDF from "jspdf";
import Logo from "../img/logo.png";
import autoTable from "jspdf-autotable";
const ComponentPdf = ({ dataPdf, contentTable, iva, total, subTotal }) => {
  let startY = 92; // Posición inicial de la tabla
  var formattedData = contentTable.map((item) => [
    item.id.toString(),
    `${item.description} ${item.descriptionExtra?? ''}`,
    item.cantVR.toString(),
    `$${item.cantUnit}`,
    `$${item.total}`,
  ]);
  formattedData.push([
    "",
    "",
    "",
    "SUBTOTAL: ",
    `$ ${subTotal}`
  ]);
  formattedData.push([
    "",
    "",
    "",
    "IVA: ",
    `$ ${iva}`
  ]);
  formattedData.push([
    "",
    "",
    "",
    "TOTAL: ",
    `$ ${total}`
  ]);

  const generatePDF = () => {
    const doc = new jsPDF();

    // Encabezado de la factura
    doc.setFontSize(12);
    doc.line(5, 10, 200, 10); //horizontal
    doc.line(5, 32, 200, 32); //  horizontal
    doc.line(200, 10, 200, 40); // vertical
    doc.line(70, 10, 70, 40); // vertical
    doc.line(70, 20, 200, 20); // horizontal
    doc.line(5, 40, 200, 40); //horizontal
    doc.line(5, 10, 5, 40); // vertical
    doc.line(130, 32, 130, 40); // vertical
    doc.addImage(
      Logo,
      "HTMLCanvasElement",
      15,
      15,
      50,
      15,
      "undefined",
      "undefined",
      0
    );
    doc.text("COTIZACIÓN DE SERVICIO", 100, 18);
    doc.text("IZAJES E INGENIERÍA DE COLOMBIA S.A.S", 80, 26);
    doc.text("IZ-F-18", 30, 38);
    doc.text("Fecha: 2022-07-04", 80, 38);
    doc.text("Versión: 01", 150, 38);

    doc.line(5, 42, 200, 42); //  horizontal
    doc.line(5, 42, 5, 50); // vertical
    doc.line(5, 50, 200, 50); //  horizontal
    doc.line(200, 42, 200, 50); // vertical
    doc.text("FECHA:", 10, 48);
    doc.text(`${dataPdf.updatedAt}`, 30, 48);
    doc.text("COTIZACIÓN N°:", 150, 48);
    doc.text(`${dataPdf.idQuotes}`, 185, 48);

    doc.line(5, 52, 200, 52); //  horizontal
    doc.line(5, 52, 5, 90); // vertical
    doc.line(5, 58, 200, 58); //  horizontal
    doc.line(200, 52, 200, 90); // vertical
    doc.line(80, 58, 80, 90); // vertical
    doc.line(5, 65, 200, 65); //  horizontal
    doc.line(5, 70, 200, 70); //  horizontal
    doc.line(5, 75, 200, 75); //  horizontal
    doc.line(5, 75, 200, 75); //  horizontal
    doc.line(5, 80, 200, 80); //  horizontal
    doc.line(5, 85, 200, 85); //  horizontal
    doc.line(5, 90, 200, 90); //  horizontal
    doc.text("INFORMACIÓN DEL CLIENTE", 75, 57);
    doc.text("CLIENTE:", 10, 64);
    doc.text(`${dataPdf.documentType == "Nit" ? dataPdf.nameOrigin : dataPdf.name}`, 90, 64);
    doc.text("NIT: ", 10, 69);
    doc.text(`${dataPdf.document}`, 90, 69);
    doc.text("CONTACTO: ", 10, 74);
    doc.text(`${dataPdf.name}`, 90, 74);
    doc.text("TELÉFONO DE CONTACTO: ", 10, 79);
    doc.text(`${dataPdf.phone}`, 90, 79);
    doc.text("DIRECCIÓN - CIUDAD: ", 10, 84);
    doc.text(`${dataPdf.address}`, 90, 84);
    doc.text("CORREO:", 10, 89);
    doc.text(`${dataPdf.mail}`, 90, 89);

    doc.autoTable({
      head: [
        ["ITEM", "DESCRIPCIÓN", "CANTIDAD", "$V/R UNITARIO", "$V/R TOTAL"],
      ],
      body: formattedData,
      startY: startY,
      theme: "grid",
      headStyles: { fillColor: [100, 100, 100] },
      styles: { fontSize: 10, cellPadding: 3 },
    });

    let finalY = doc.lastAutoTable.finalY - 10;
    startY = finalY + 100;

    if (startY >= doc.internal.pageSize.height - 40) { // Si no hay suficiente espacio en la página actual, agrega una nueva página
      doc.addPage();
      finalY = 10;
    }
    //let finalT = doc.lastAutoTable.finalY;
    /*doc.line(122.6, finalT, 122.6, finalY + 12);
    doc.text("SUBTOTAL: ", 130, finalY);
    doc.text(`$ ${subTotal}`, 165, finalY);

    doc.line(122.6, finalY + 1, 195.7, finalY + 1);
    doc.text("IVA: ", 130, finalY + 5);
    doc.text(`$ ${iva}`, 165, finalY + 5);

    doc.line(122.6, finalY + 6, 195.7, finalY + 6);
    doc.text("TOTAL:", 130, finalY + 11);
    doc.text(`$ ${total}`, 165, finalY + 11);

    doc.line(122.6, finalY + 12, 195.7, finalY + 12);
    doc.line(195.7, finalT, 195.7, finalY + 12);
    doc.line(163, finalT, 163, finalY + 12);*/

    doc.line(5, finalY + 15, 200, finalY + 15); //  horizontal
    doc.line(5, finalY + 15, 5, finalY + 38); // vertical
    doc.line(70, finalY + 15, 70, finalY + 21); // vertical
    doc.text("FORMA DE PAGO", 10, finalY + 20);
    doc.text(`${dataPdf.selectFormBuy}`, 75, finalY + 20);
    doc.line(5, finalY + 21, 200, finalY + 21); //  horizontal
    doc.line(200, finalY + 15, 200, finalY + 38); // vertical
    doc.text(
      "Consignar a nombre de IZAJES E INGENIERIA DE COLOMBIA SAS Nit: 901.444.810–3 a la cuenta de ahorros No 39500001489 Banco BANCOLOMBIA.",
      10,
      finalY + 26,
      { maxWidth: 190 }
    );
    doc.line(5, finalY + 32, 200, finalY + 32); //  horizontal
    doc.line(70, finalY + 32, 70, finalY + 38); // vertical
    doc.text("VALIDEZ DE LA OFERTA", 10, finalY + 37);
    doc.text(`${dataPdf.validateDay}`, 75, finalY + 37);
    doc.line(5, finalY + 38, 200, finalY + 38); //  horizontal

    doc.line(5, finalY + 40, 200, finalY + 40); //  horizontal
    doc.line(5, finalY + 40, 5, finalY + 92); // vertical
    doc.text("TÉRMINOS Y CONDICIONES", 80, finalY + 45);
    doc.line(5, finalY + 46, 200, finalY + 46);
    doc.text(
      "Nota 1: Si se requiere realizar inspección complementaria, el costo corresponde al valor de los viáticos por la prestación de este servicio.",
      8,
      finalY + 50,
      { maxWidth: 190 }
    );
    doc.line(5, finalY + 56, 200, finalY + 56);
    doc.text(
      "Nota 2: En caso de requerir presentar nuevamente un examen, el costo corresponde al valor de los viáticos por la prestación de este servicio.",
      8,
      finalY + 60,
      { maxWidth: 190 }
    );
    doc.line(5, finalY + 66, 200, finalY + 66);
    doc.text(
      "Nota 3: La aceptación del servicio implica que el cliente conoce, entiende y acepta las condiciones contenidas en el alcance y en las reglas del servicio.",
      8,
      finalY + 70,
      { maxWidth: 190 }
    );
    doc.line(5, finalY + 76, 200, finalY + 76);
    doc.text("Anexo: Reglas del servicio, OCP https://izaincol.com.co/files/reglas_del_servicio_de_certificacion.pdf, OIN https://izaincol.com.co/files/reglas_del_servicio_de_certificacion_equipos.pdf", 8, finalY + 80, { maxWidth: 190 });
    doc.line(5, finalY + 91, 200, finalY + 91);
    doc.text(
      "Si el cliente acepta esta cotización deberá enviar respuesta al correo comercial@izaincol.com, con la aceptación o una orden de servicio..",
      8,
      finalY + 96,
      { maxWidth: 190 }
    );
    doc.line(5, finalY + 102, 200, finalY + 102); //
    doc.line(200, finalY + 50, 200, finalY + 102); // vertical


    if (dataPdf.nameSignature) {
      doc.text(dataPdf.nameSignature, 25, finalY + 120);
    }
    if (dataPdf.charges) {
      doc.text(dataPdf.charges, 25, finalY + 125);
    }

    // Añadir el pie de página
    const addFooter = () => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.text("Dir. Cra 30 N° 4B-25 El Sociego", 10, 285);
        doc.text("Villavicencio – Meta", 10, 290);
        doc.text("CORREO: comercial@izaincol.com", 90, 285);
        doc.text("Cel: 3226236631-3224218547", 90, 290);
      }
    };

    addFooter();

    // doc.text("GERENTE GENERAL IZAINCOL S.A.S", 10, finalY + 195);

    // Guardar el PDF
    doc.save(`cotizacion #${dataPdf.idQuotes}.pdf`);
  };

  return (
    <div>
      <button onClick={generatePDF} className="btn btn-primary" disabled={dataPdf.idQuotes === undefined} >
        Generar pdf <i className="fa-solid fa-file-pdf"></i>
      </button>
    </div>
  );
};

export default ComponentPdf;
