
const API_URL = 'https://izaincol.com.co/apiv1-prod'

const checkCookiesExist = () => {
    const cookies = document.cookie.split('; ');
    var exists = cookies.some(cookie => cookie.startsWith(`IZAIIIIII=`));
    if(!exists){
       window.location.href = '/';
    }
};

export default async function request(args) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/?func=${args.route}`, {
            method: args.method,
            body: JSON.stringify(args.data),
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data) 
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

export  async function getData(args) {
    checkCookiesExist();
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/?func=${args.route}`, {
            method: 'GET',
            body: JSON.stringify(args.data),
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data) 
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

export async function postDataExternal(args) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/?func=${args.route}`, {
            method: 'POST',
            body: JSON.stringify(args.data),
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data) 
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

export async function postData(args) {
    checkCookiesExist();
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/?func=${args.route}`, {
            method: 'POST',
            body: JSON.stringify(args.data),
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data) 
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

export async function postDataFile(args) {
    checkCookiesExist();
    return new Promise((resolve, reject) => {
        const { folder, id, fileName, file } = args.data;
        const formData = new FormData();
        formData.append("folder", folder);
        formData.append("id", id);
        formData.append("fileName", fileName);
        formData.append("file", file);
    
        const requestOptions = {
            method: "POST",
            body: formData,
            redirect: "follow",
        };
    
        fetch(`${API_URL}/?func=uploadFile`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            resolve(JSON.parse(result));
        })
        .catch((error) => {
        console.error(error);
        reject(error);
        });
    });
}
  
export  async function updateData(args) {
    checkCookiesExist();
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/?func=${args.route}`, {
            method: 'POST', //PATCH
            body: JSON.stringify(args.data),
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data) 
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}
export  async function delateData(args) {
    checkCookiesExist();
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/?func=${args.route}`, {
            method: 'POST', //DELETE
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data) 
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

export  async function getDepartments() {
    checkCookiesExist();
    return new Promise((resolve, reject) => {
        fetch(`https://www.datos.gov.co/resource/xdk5-pm3f.json`, {
            method: 'GET',
            cache: 'no-store'
        }).then((response) => response.json())
        .then((data) => {
            resolve(data)
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}