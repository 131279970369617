import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { CREATE_OCP, ACCORDION_OCP } from "../constants";
import { fillDefaultValues } from "../helpers/filler";
import DatePicker from "react-datepicker";
import { Modal, Button, Accordion, Carousel } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { postData, getData, postDataFile } from "../helpers/request";
import Swal from "sweetalert2";
import _ from "lodash";
import "../css/componentOcp.css";
import ImageCarouselModal from "./ImageCarouselModal";

const EMPTY_OCP = fillDefaultValues(CREATE_OCP);

const ComponentCreateOcp = ({ getOcp }) => {
  const [newOcp, setNewOcp] = useState(EMPTY_OCP);
  const [idOcp, setIdOcp] = useState(0);
  const [filesList, setFilesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagensList, setImagensList] = useState([]);
  const [timerInput, setTimerInput] = useState(null);
  const [specialNeeds, setSpecialNeeds] = useState(false);
  

  const editOcp = () => {
    if (getOcp) {
      setIdOcp(getOcp.idOcp);
      searchCustomer(getOcp);
    }
  };

  const searchQuotation = (value) => {
    if (value) {
      value.date = null;
      getData({
        route: `getQuotation&id=${value.idQuotation}`,
      }).then((response) => {
        if (response.success) {
          response.data.identificationDocument = response.data.document;
          response.data.idQuotation = response.data.id;
          searchCustomer(response.data);
        }
      });
    }
  };
  const searchCustomer = (value) => {
    if (value) {
      value.date = null;
      getData({
        route: `getClientByDocument&document=${value.identificationDocument}`,
      }).then((response) => {
        if (response.success) {
          response.data.identificationDocument = response.data.document;
          const parts = _.split(value.specialNeeds, ',');
          value.specialNeeds = parts[0];
          setSpecialNeeds(parts[0] == 'Si');
          value.specialNeedsText = parts[1];
          setNewOcp({
            ...value,
            ...response.data,
          });
        }
      });
    }
  };

  const btnAction = async (event, filName) => {
    if (filName === "linkEvaluationOne") {
      const examTitle = "Examen Teórico para Izaincol";
      const examDescription =
        "¡Hola! Adjunto te envío el enlace al examen teórico para la empresa Izaincol. Por favor, tómate el tiempo para completarlo. ¡Buena suerte!";
      const termsAndConditions =
        "Al realizar este examen, aceptas los términos y condiciones establecidos por Izaincol.";
      const urlTest = encodeURIComponent("https://izaincol.com.co/#/theoretical-evaluation");
      const whatsappMessage = `${examTitle}%0A%0A${examDescription}%0A%0A${urlTest}%0A%0A${termsAndConditions}`;
      const whatsappURL = `https://api.whatsapp.com/send?phone=${newOcp.phone}&text=${whatsappMessage}`;
      window.open(whatsappURL);
    }
    if (filName === "linkEvaluationTwo") {
      window.open(
        "https://izaincol.com.co/files/lista_de_chequeo_examen_practico_para_operador_de_grua%20_movil_y_locomotora.pdf"
      );
    }
    if (filName === "theoretical") {
      openFileIfExists("theorical_evaluation.pdf");
    }
    if (filName === "viewPre") {
      openFileIfExists("documento.pdf");
      openFileIfExists("licencia.pdf");
      openFileIfExists("certificadoAlturas.pdf");
    }
    if (filName === "uploadChecklist") {
      openFileIfExists("uploadChecklist.pdf");
    }    
    if (filName === "viewCheck") {
      const nameFile = "fotoRegistrosPruebaPractica";
      let listFiletsViewCheck = [];
      for (let j = 0; j < 20; j++) {
        const nameFileJ = `${nameFile}${j}.png`;
        var resIm = await openFileIfExistsBool(nameFileJ);
        if (resIm) {
          listFiletsViewCheck.push(
            `https://izaincol.com.co/files/records/OCP/${idOcp}/${nameFileJ}`
          );
        } else {
          break;
        }
      }
      setImagensList(listFiletsViewCheck);
      setIsModalOpen(true);
    }
    if (filName === "certificateRequestPre") {
      openFileIfExists("certificateRequestPre.pdf");
    }    
    if (filName === "generateCertificate") {
      if (idOcp === 0 || idOcp === undefined) {
        Swal.fire({
          title: "Advertencia",
          text: "Debe guardar primero un registro ocp para generar el certificado",
          icon: "warning",
        });
        return;
      }
      const dateSend = new Date();
      const year = dateSend.getFullYear() + 1;
      const month = (dateSend.getMonth() + 1).toString().padStart(2, '0');
      const day = dateSend.getDate().toString().padStart(2, '0');

      const norma = newOcp.scope.match(/\(([^)]+)\)$/);
      const inicioScope = newOcp.scope.indexOf("(");
      const objetCertificate = {
        validity: year + "-" + month + "-" + day,
        ability: newOcp.capacity + "-" + newOcp.capacityType,
        state: "Activo",
        expedition: dateSend.getFullYear() + "-" + month + "-" + day,
        verification_code: dateSend.getFullYear() + "-OCP-" + idOcp,
        rule: _.replace(norma[1], 'norma', ''),
        name: newOcp.name,
        document: newOcp.identificationDocument,
        scope: inicioScope !== -1 ? newOcp.scope.substring(0, inicioScope).trim() : "",
        idOcp
      }
      console.log(objetCertificate);

      postData({
        route: "createCertificatesUser",
        data: objetCertificate,
      })
        .then((response) => {
          console.log("response ", response);
          if (response.success) {
            Swal.fire({
              position: "position-absolute bottom-0 end-0",
              icon: "success",
              title: "Guardado correctamente",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "warning",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            title: "Error",
            text: e.message,
            icon: "warning",
          });
        });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openFileIfExistsBool = async (fileName) => {
    try {
      const fileUrl = `https://izaincol.com.co/files/records/OCP/${idOcp}/${fileName}`;
      const response = await fetch(fileUrl, { method: "HEAD" });
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const openFileIfExists = async (fileName) => {
    try {
      const fileUrl = `https://izaincol.com.co/files/records/OCP/${idOcp}/${fileName}`;
      const response = await fetch(fileUrl, { method: "HEAD" });
      if (response.ok) {
        window.open(fileUrl);
      } else {
        Swal.fire(
          "Archivo no encontrado",
          `El archivo aún no ha sido subido.`,
          "warning"
        );
      }
    } catch (error) {
      Swal.fire(
        "Archivo no encontrado",
        `El archivo aún no ha sido subido.`,
        "warning"
      );
    }
  };

  const saveOcp = () => {
    newOcp.identificationDocument = newOcp.document;
    newOcp.clientId = newOcp.id;
    newOcp.specialNeeds = newOcp.specialNeeds == 'Si' ? `${newOcp.specialNeeds},${newOcp.specialNeedsText}`: newOcp.specialNeeds;
    let dateStr = newOcp.date;
    let dateObj = new Date(dateStr);
    let formattedDate = dateObj.toISOString().split("T")[0];
    var bodyParams = { ...newOcp, ...{ date: formattedDate } };
    if (idOcp != 0 && idOcp != null) {
      bodyParams = { ...bodyParams, ...{ id: idOcp } };
    }
    postData({
      route: idOcp != 0 && idOcp != null ? "updateOcpRecord" : `createOcpRecord`,
      data: bodyParams,
    }).then((response) => {
      if (response.success) {
        let idOcpR = 0;
        if (response.success && response.data) {
          setIdOcp(response.data);
          idOcpR = response.data;
        } else {
          idOcpR = idOcp;
        }
        Swal.fire({
          position: "end",
          icon: "success",
          title: `OCP guardado correctamente con el id: ${idOcpR}`,
          showConfirmButton: true,
        });
        if (filesList.length > 0) {
          uploadFiles(idOcpR);
        }
      } else {
        Swal.fire({
          position: "end",
          icon: "error",
          title: response.error.message,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    });
  };

  const uploadFiles = async (id) => {
    Swal.showLoading();
    let errorFile = false;
    let namesFilesError = "";
    let totalFiles = filesList.length - 1;
    for (let index = 0; index < filesList.length; index++) {
      const fileObj = filesList[index];
      const extent = fileObj.customFileName.split(".").pop().toLowerCase();
      if (fileObj.file.name.split(".").pop().toLowerCase() != extent && !fileObj.file.type.includes("image")) {
        namesFilesError += `<p>El archivo ${fileObj.file.name} tiene que ser un .${extent}</p>`;
        errorFile = true;
      }
    }
    if (errorFile) {
      Swal.fire({
        icon: "info",
        footer: namesFilesError,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return;
    }
    for (let index = 0; index < filesList.length; index++) {
      const fileObj = filesList[index];
      const response = await postDataFile({
        data: {
          folder: "OCP",
          id: id,
          fileName: fileObj.customFileName,
          file: fileObj.file,
        },
      });
      if (response.success) {
        if (index == totalFiles) {
          Swal.fire({
            position: "end",
            icon: "success",
            title: `Todos los archivos han sido subidos correctamente, id OCP: ${id}`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: "end",
          icon: "error",
          title: `Error al subir el archivo ${fileObj.customFileName}`,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
  };

  const handleChange = (event) => {
    var dataOcp = {
      ...newOcp,
      [event.target.name]: event.target.value,
    };
    if (event.target.name === "idQuotation") {
      clearTimeout(timerInput);
      const newTimer = setTimeout(() => {
        searchQuotation(dataOcp);
      }, 1000);
      setTimerInput(newTimer);
    }
    if (event.target.name === "identificationDocument") {
      clearTimeout(timerInput);
      const newTimer = setTimeout(() => {
        searchCustomer(dataOcp);
      }, 1000);
      setTimerInput(newTimer);
    }
    setNewOcp(dataOcp);

    if (!event.target.value) {
      setNewOcp(EMPTY_OCP);
    }
    setSpecialNeeds(dataOcp.specialNeeds == 'Si');
  };

  const dateChange = (date, fieldName) => {
    setNewOcp({
      ...newOcp,
      [fieldName]: date,
    });
  };

  const handleFileChange = (event, field) => {
    let filesListClone = _.cloneDeep(filesList);
    for (let j = 0; j < event.target.files.length; j++) {
      let nameField = `${field.filName}.${field.typeFile}`;
      if (field.typeFile == "png") {
        nameField = `${field.filName}${j}.png`;
      }
      const elementFile = event.target.files[j];
      const index = _.findIndex(filesList, { customFileName: nameField });
      if (index !== -1) {
        filesListClone[index] = {
          file: elementFile,
          customFileName: nameField,
        };
      } else {
        filesListClone.push({
          file: elementFile,
          customFileName: nameField,
        });
      }
    }
    setFilesList(filesListClone);
  };

  const renderUserInputType = (field, selectDefault = 0) => {
    switch (field.type) {
      case "file":
        if (field.typeFile == "pdf") {
          return (
            <div>
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, field)}
              />
            </div>
          );
        } else if (field.typeFile == "png") {
          return (
            <div>
              <input
                className="form-control"
                type="file"
                multiple
                accept="image/jpeg,image/png"
                onChange={(e) => handleFileChange(e, field)}
              />
            </div>
          );
        }
      case "button":
        return (
          <Button
            className="btn btn-positive m-lg-2"
            onClick={(e) => btnAction(e, field.name)}
          >
            {field.displayName}
          </Button>
        );
      case "date":
        return (
          <DatePicker
            className="form-control"
            selected={newOcp[field.name]}
            onChange={(date) => dateChange(date, field.name)}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
          />
        );
      case "select":
        return (
          <Form.Select
            onChange={handleChange}
            name={field.name}
            value={selectDefault}
          >
            <option key={0} value={0}>
              ...
            </option>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={newOcp[field.name]}
            onChange={handleChange}
            disabled={field.disable === 1}
          />
        );
    }
  };

  useEffect(() => {
    try {
      if (getOcp) {
        editOcp();
      }
    } catch (error) {
      console.log(error);
    }
  }, [getOcp]);

  return (
    <div>
      <Form className="row g-3">
        {CREATE_OCP.map((field, index) => {
          if (field.name === "scopeType") {
            if (
              newOcp.scope ===
              "Operador de grúas móviles y locomotoras (norma ASME B30.5-2021)"
            ) {
              return (
                <Form.Group key={index} className={field.stile}>
                  <Form.Label>{field.displayName}</Form.Label>
                  {renderUserInputType(field, newOcp[field.name])}
                </Form.Group>
              );
            } else {
              return null;
            }
          }
          if (field.name === "scopeTypeTwo") {
            if (
              newOcp.scope ===
              "Operador de montacargas y apiladores (norma ANSI/ITSDF B56.1-2020)"
            ) {
              return (
                <Form.Group key={index} className={field.stile}>
                  <Form.Label>{field.displayName}</Form.Label>
                  {renderUserInputType(field, newOcp[field.name])}
                </Form.Group>
              );
            } else {
              return null;
            }
          }
          return !field.validation || specialNeeds ? (
            <Form.Group key={index} className={field.stile}>
              <Form.Label>{field.displayName}</Form.Label>
              {renderUserInputType(field, newOcp[field.name])}
            </Form.Group>
          ) : (<></>);
        })}
        <div className="d-flex p-2">
          Utilice esta herramienta antes de subir documentos:
          <a
            href="https://www.ilovepdf.com/es/comprimir_pdf"
            target="_blank"
            rel="noreferrer"
          >
            PDF
          </a>
          <b> - </b>
          <a
            href="https://www.iloveimg.com/es/comprimir-imagen"
            target="_blank"
            rel="noreferrer"
          >
            IMAGEN
          </a>
        </div>
        <Accordion>
          {ACCORDION_OCP.map((item, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  {item.content.map((field, index) => (
                    <Form.Group key={index} className={field.stile}>
                      {field.type !== "button" && (
                        <Form.Label>
                          {field.displayName}{" "}
                          <span className="span-info">{field.textInfo}</span>
                        </Form.Label>
                      )}
                      {renderUserInputType(field, newOcp[field.name])}
                    </Form.Group>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <div>
          <Modal.Footer>
            <Button onClick={saveOcp}>
              Guardar ocp <i className="fa-solid fa-floppy-disk"></i>
            </Button>
          </Modal.Footer>
        </div>
      </Form>
      <ImageCarouselModal
        isOpen={isModalOpen}
        onClose={closeModal}
        images={imagensList}
      />
    </div>
  );
};

export default ComponentCreateOcp;
