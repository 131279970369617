import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { CREATE_OIN, ACCORDION_OIN, CREATE_OIN_UBICATION } from "../constants";
import { fillDefaultValues } from "../helpers/filler";
import DatePicker from "react-datepicker";
import { Accordion } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { postData, getData, getDepartments, postDataFile } from "../helpers/request";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import _, { forIn } from "lodash";
import Quotes from "../components/componentCreateQuotes";

const EMPTY_OIN = fillDefaultValues(CREATE_OIN);
const ComponentOin = ({ getOin }) => {
  const [newOin, setNewOin] = useState(EMPTY_OIN);
  const [departments, setDepartments] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [dataGov, setDataGov] = useState([]);
  const [department, setDepartment] = useState("");
  const [city, setCity] = useState("");

  const [itemAdd, setItemAdd] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [timerInput, setTimerInput] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [idOin, setIdOin] = useState(0);
  const [quotes, getQuotes] = useState([]);
  const [newService, setService] = useState([]);

  const editOin = () => {
    getQuotes({
        document: getOin.document
    });
    if(getOin.cityAndDepartment){
      const parts = _.split(getOin.cityAndDepartment, '/');
      setDepartment(parts[0]);
      const dataMunicipio = dataGov.filter((item) =>
        item.departamento.includes(parts[0])
      );
      setMunicipios(dataMunicipio);
      setCity(parts[1]);
    }
    if(getOin.items != ""){
      const itemsLoads = JSON.parse(getOin.items);
      let dataTableLo = [];
      for (const key in itemsLoads) {
        dataTableLo = addTable(itemsLoads[key], dataTableLo);
      }
    }
    searchCustomer(getOin);
    if(getOin.idQuotation){
      getQuotation(getOin.idQuotation);
    }
  };

  const getQuotation = (value) => {
    getData({
      route: `getQuotation&id=${value}`,
    })
      .then((response) => {
        if (response.success) {
          getQuotes(response.data);
        }
      })
      .finally(() => {
      });
  }

  const searchCustomer = (value) => {
    if(value){
      value.date = new Date(value.date);
      getData({
        route: `getClientByDocument&document=${value.document}`,
      }).then((response) => {
        if (response.success) {
          response.data.clientId =  response.data.id
          response.data.id =  0;
          setNewOin({
            ...value,
            ...response.data
          });
          setIdOin(value.id)
        }else{
          console.log("no hay datos");
        }
      });
    }
  };

  const dowloandFile = (urlFile) => {
    const link = document.createElement('a');
    link.href = urlFile;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const btnAction = (event, filName) => {
    if (filName === "createQuotation") {
      if(idOin){
        setShow(true)
      } else {
        Swal.fire({
          icon: "info",
          title: "Para generar una cotizacion primero debes guardar el oin",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else if (filName === "security"){
      dowloandFile('https://izaincol.com.co/files/seguridadi_inspección_de_equipos.docx');
    } else if (filName === "reportInspection"){
      dowloandFile('https://izaincol.com.co/files/prueba_operacional_de_estabilidad_y_carga_de_grua_movil_y_Locomotora.xlsx');
    } else if (filName === "inspectionRecordsFoto"){
      dowloandFile('https://izaincol.com.co/files/registro_fotografico_inspeccion.xlsx');
    } else
    if(filName === "sendOt"){
      if(idOin != undefined && idOin != 0){
        const otn = {
          idOin,
          name: newOin.createJob,
          inspector: newOin.assignInspector
        }
        postData({
          route: "createdWorkOrder",
          data: otn
        }).then((response) => {
          if (response.success) {
            Swal.fire({
              position: "end",
              icon: "success",
              title: "Ot guardada correctamente",
              showConfirmButton: false,
              timer: 2000,
            });
          }else{
            console.log("no hay datos");
          }
        });
        } else {
        Swal.fire({
          icon: "info",
          title: "Para generar una orden de trabajo primero debes guardar el oin",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else if(filName === 'generateCertificate'){
      saveGroupCertificate();
    }
    if(filName === "serviceRecordActa"){
      openFileIfExists("serviceRecordActa.docx")
    }
    if(filName === "inspectionRecordNew"){
      openFileIfExists("inspectionRecordNew.pdf")
    }    
    if(filName === "securityWord"){
      openFileIfExists("securityWord.docx")
    }
    if(filName === "viewFormatInspection"){
       openFileIfExists("fotoInspeccionRecord.xlsx")
    }
    if(filName === "viewRegisterInspection"){
      openFileIfExists("registroPruebaDeCarga.xlsx")
    }
  };

  const openFileIfExists = async (fileName) => {
    try {
      const fileUrl = `https://izaincol.com.co/files/records/OIN/${idOin}/${fileName}`;
      const response = await fetch(fileUrl, { method: "HEAD" });
      if (response.ok) {
        window.open(fileUrl);
      } else {
        Swal.fire(
          "Archivo no encontrado",
          `El archivo aún no ha sido subido.`,
          "warning"
        );
      }
    } catch (error) {
      Swal.fire(
        "Archivo no encontrado",
        `El archivo aún no ha sido subido.`,
        "warning"
      );
    }
  };

  const saveGroupCertificate = () => {
    Swal.showLoading();
    const dateSend = new Date();
    const name_group = _.replace(_.replace(newOin.requiredInspectionScope, 'Inspección de', ''), 'Inspección por', '');
    const month = (dateSend.getMonth() + 1).toString().padStart(2, '0');
    const day = dateSend.getDate().toString().padStart(2, '0');
    
    postData({
      route: "createCertificatesGroup",
      data: {
        "certificate": dateSend.getFullYear() + "-OIN-" + idOin,
        "name_group": name_group,
        "type": "N/A",
        "rule": "Norma",
        "brand": "Marca",
        "serie": "Serie",
        "model": "Modelo",
        idOin: idOin,
        "expedition_date": dateSend.getFullYear() + "-" + month + "-" + day
      }
    })
      .then((response) => {
        Swal.close(); 
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: "Guardado correctamente",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
          title: "Error",
          text: response.message,
          icon: "warning"
        });
        }
      })
      .catch((e) => {
        Swal.close(); 
        Swal.fire({
          title: "Error",
          text: e.message,
          icon: "warning"
        });
      })
      .finally(() => {
      });
  };

  // guarda el formulario
  const handleChange = (event) => {
    const dtt = {
      ...newOin,
      [event.target.name]: event.target.value,
    };
    setNewOin(dtt);
    if (event.target.name === "document") {
      clearTimeout(timerInput);
      const newTimer = setTimeout(() => {
        searchCustomer(dtt);
      }, 1000);
      setTimerInput(newTimer);
      if(!event.target.value){
        setNewOin(EMPTY_OIN)
      }
    }
  };

  //   enviar la hora
  const handleUserDateChange = (date, fieldName) => {
    setNewOin({
      ...newOin,
      [fieldName]: date,
    });
  };

  const uploadFiles = async (id) => {
    Swal.showLoading();
    let errorFile = false;
    let namesFilesError = "";
    let totalFiles = filesList.length - 1;
    for (let index = 0; index < filesList.length; index++) {
      const fileObj = filesList[index];
      const extent = fileObj.customFileName.split(".").pop().toLowerCase();
      if (fileObj.file.name.split(".").pop().toLowerCase() != extent && !fileObj.file.type.toLowerCase().includes("image")) {
        namesFilesError += `<p>El archivo ${fileObj.file.name} tiene que ser un .${extent}</p>`;
        errorFile = true;
      }
    }
    if (errorFile) {
      Swal.fire({
        icon: "info",
        footer: namesFilesError,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return;
    }
    for (let index = 0; index < filesList.length; index++) {
      const fileObj = filesList[index];
      const response = await postDataFile({
        data: {
          folder: "OIN",
          id: id,
          fileName: fileObj.customFileName,
          file: fileObj.file,
        },
      });
      if (response.success) {
        if (index == totalFiles) {
          Swal.fire({
            position: "end",
            icon: "success",
            title: "Todos los archivos han sido subidos correctamente",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: "end",
          icon: "error",
          title: `Error al subir el archivo ${fileObj.customFileName}`,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
  };

  const getServices = () => {
    getData({
      route: `getService`,
    }).then((response) => {
      if (response.success) {
        setService(response.data);
      }
    });
  };

  const handleFileChange = (event, field) => {
    let filesListClone = _.cloneDeep(filesList);
    for (let j = 0; j < event.target.files.length; j++) {
      let nameField = `${field.filName}.${field.typeFile}`;
      if (field.typeFile == "png") {
        nameField = `${field.filName}${j}.png`;
      }
      const elementFile = event.target.files[j];
      const index = _.findIndex(filesList, { customFileName: nameField });
      if (index !== -1) {
        filesListClone[index] = {
          file: elementFile,
          customFileName: nameField,
        };
      } else {
        filesListClone.push({
          file: elementFile,
          customFileName: nameField,
        });
      }
    }
    setFilesList(filesListClone);
  };
  //  newOin.feasibility === "NO" ? "btn btn-positive m-lg-2 disabled" : "btn btn-positive m-lg-2"
  const renderUserInputType = (field, selectDefault = "0") => {
    switch (field.type) {
      case "button":
        return (
          <Button
            className= {field.name === "createQuotation" && idOin === 0 ? "btn btn-positive m-lg-2 disabled" : "btn btn-positive m-lg-2"}
            onClick={(e) => btnAction(e, field.name)}
          >
            {field.displayName}
          </Button>
        );
        case "file":
        if (field.typeFile === "pdf") {
          return (
            <div>
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, field)}
              />
            </div>
          );
        } else if (field.typeFile === "png") {
          return (
            <div>
              <input
                className="form-control"
                type="file"
                multiple
                accept=".jpeg,.jpg,.png"
                onChange={(e) => handleFileChange(e, field)}
              />
            </div>
          );
        } else if (field.typeFile === "xlsx") {
          return (
            <div>
              <input
                className="form-control"
                type="file"
                multiple
                accept=".xls,.xlsx"
                onChange={(e) => handleFileChange(e, field)}
              />
            </div>
          );
        } else if (field.typeFile == "docx") {
          return (
            <div>
              <input
                className="form-control"
                type="file"
                multiple
                accept=".doc,.docx"
                onChange={(e) => handleFileChange(e, field)}
              />
            </div>
          );
        }        
      // eslint-disable-next-line no-fallthrough
      case "date":
        return (
          <DatePicker
            className="form-control"
            selected={newOin[field.name]}
            onChange={(date) => handleUserDateChange(date, field.name)}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
          />
        );
      case "select":
        return (
          <Form.Select 
            onChange={handleChange}
            name={field.name}
            value={selectDefault}
          >
            <option key={0} value={0}>
              Seleccione
            </option>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={newOin[field.name]}
            onChange={handleChange}
            disabled={field.disable === 1}
          />
        );
    }
  };

  const saveOin = (idQuotation = 0) => {
    if(newOin.date == ""){
      Swal.fire({
        icon: "info",
        title: "Falta seleccionar la fecha",
      });
      return;
    }
    let dateStr = newOin.date;
    let dateObj = new Date(dateStr);
    let formattedDate = dateObj.toISOString().split("T")[0];
    newOin.cityAndDepartment = department + "/" + city;
    const ids = dataTable.map(item => item.id)
    newOin.items = JSON.stringify(ids);
    newOin.id = idOin;

    if(idQuotation){
      newOin.idQuotation = idQuotation;
    }
    postData({
      route:  idOin != 0 && idOin != undefined ? `updateOinRecord `: `createOinRecord`,
      data: { ...newOin, ...{ date: formattedDate }},
    }).then((response) => {
      if (response.success) {
        let idOinResponseOrLoad = idOin;
        if(response.data){
          setIdOin(response.data);
          idOinResponseOrLoad = response.data;
        }
        Swal.fire({
          icon: "success",
          title: idOin != 0 && idOin != undefined ? "Se actualizo correctamente" :`Servicio guardado correctamente con el id: ${response.data}`,
          showConfirmButton: true,
        });
        if (filesList.length > 0) {
          uploadFiles(idOinResponseOrLoad);
        }
        
      } else {
        Swal.fire({
          position: "end",
          icon: "error",
          title: response.error.message,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    });
  };

  const onChangeSelectDepartments = (event) => {
    setDepartment(event.target.value);
    const dataMunicipio = dataGov.filter((item) =>
      item.departamento.includes(event.target.value)
    );
    setMunicipios(dataMunicipio);
  };
  const onChangeSelectMunicipios = (event) => {
    setCity(event.target.value);
  };

  const dataDep = () => {
    getDepartments().then((response) => {
      setDataGov(response);
      const uniqueDepartments = new Set(
        response.map((item) => item.departamento)
      );
      setDepartments(Array.from(uniqueDepartments));
    });
  };

  // agregar a la tabla
  const addTable = (idLoad, dataTableLo) => {
    let idItemTable;
    if(idLoad != null){
      idItemTable = idLoad;
    }else {
      idItemTable = itemAdd;
    }
    if (idItemTable === "0") {
      return;
    }
    const dataSend = selectItem.find((item) => item.id === parseFloat(idItemTable));
    const itemNew = {
      id: dataSend.id,
      idView: _.size(dataTableLo) + 1,
      description: dataSend.name,
    };
    console.log("dataSend ", dataSend);
    const newDataT = [...dataTableLo, itemNew];
    setDataTable(newDataT);
    return newDataT;
  };

  const getItems = () => {
    getData({
      route: `listElements`,
    }).then((response) => {
      if (response.success) {
        setSelectItem(response.data);
      }
    });
  };

  const createItemsOin = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Agregar nuevo item",
      html: `
        <input id="name" class="swal2-input" type="text" placeholder="Nombre del item">
      `,
      focusConfirm: false,
      preConfirm: () => {
        return [document.getElementById("name").value];
      },
    });
    if (formValues) {
      let saveItems = {
        name: formValues[0],
      };
      postData({
        route: `createElements`,
        data: saveItems,
      }).then((response) => {
        if (response.success) {
          Swal.fire({
            position: "end",
            icon: "success",
            title: "Servicio guardado correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
          getItems();
        }
      });
    }
  };

  const onChangeSelectItem = (event) => {
    setItemAdd(event.target.value);
  };

  const deleteRow = (idElement) => {
    const newDataT = dataTable.filter(item => item.id !== idElement);
    setDataTable(newDataT);
  };

  const saveIdQuatation = (v) => {
    setShow(false);
    saveOin(v);
    getQuotation(v);
  }

  useEffect(() => {
    try {
      getServices();
      getItems();
      if (getOin && getOin.id) {
        editOin();
      } else {
        setIdOin(0)
        dataDep();
      }
    }catch (error) {
      console.log(error);
    }
  }, [getOin]);

  return (
    <div>
      <Form className="row g-3">
        {CREATE_OIN.map((field, index) => (
          <Form.Group key={index} className={field.stile}>
            <Form.Label>{field.displayName}</Form.Label>
            {renderUserInputType(field, newOin[field.name])}
          </Form.Group>
        ))}
        <div className="text-center">
          <h4>INFORMACIÓN DEL EQUIPO O ELEMENTO (S)</h4>
        </div>
        <div className="d-flex p-2">
          En caso de requerir inspección de varios equipos en un mismo alcance
          realizar la descripción en ítem separados. Para los elementos de izaje
          indicar la cantidad en la descripción separados por ítems según su
          alcance (eslingas, grilletes y/o ganchos).
        </div>
        <div className="col-12">
          <table className="table-items">
            <tr>
              <td>
                <Form.Select onChange={onChangeSelectItem} name="name">
                  <option key={0} value={0}>
                    Selecciona un servicio
                  </option>
                  {selectItem.map((field, index) => (
                    <option key={index} value={field.id}>
                      {field.name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <Button onClick={() => addTable(null, dataTable)}>
                  Agregar&nbsp;
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </Button>
              </td>
              <td>
                <Button onClick={createItemsOin}>
                  Crear&nbsp;
                  <i className="fa-solid fa-file-circle-plus"></i>
                </Button>
              </td>
            </tr>
          </table>
        </div>
        <Table bordered hover className="table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Descripción de equipo o elemento</th>
            </tr>
          </thead>
          <tbody>
            {dataTable.map((value, index) => (
              <tr key={index}>
                <td>{value.idView}</td>
                <td>{value.description}</td>
                <td>
                  <Button variant="danger" onClick={() => deleteRow(value.id)}>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="col-md-4 mt-2.5">
          <Form.Label>Departamento</Form.Label>
          <Form.Select onChange={onChangeSelectDepartments} value={department} name="name">
            <option key={0} value={0}>
              Seleccione un Departamento
            </option>
            {departments.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="col-md-4">
          <Form.Label>Municipio</Form.Label>
          <Form.Select onChange={onChangeSelectMunicipios} value={city} name="name">
            <option key={0} value={0}>
              Seleccione un Municipio
            </option>
            {municipios.map((field, index) => (
              <option key={index} value={field.municipio}>
                {field.municipio}
              </option>
            ))}
          </Form.Select>
        </div>

        {CREATE_OIN_UBICATION.map((field, index) => (

          <Form.Group key={index} className={field.stile}>
            {field.type !== "button" && (
              <Form.Label>{field.displayName}</Form.Label>
            )}
            {renderUserInputType(field, newOin[field.name])}
          </Form.Group>
        )
        )}
        <div className="d-flex p-2">
          Utilice esta herramienta antes de subir documentos:
          <a
            href="https://www.wecompress.com/es/"
            target="_blank"
            rel="noreferrer"
          >
            LINK
          </a>
        </div>
        <Accordion>
          {ACCORDION_OIN.map((item, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{item.title}</Accordion.Header>

              <Accordion.Body>
                <div className="row">
                  {item.content.map((field, index) => (
                    <Form.Group key={index} className={field.stile}>
                      {field.type !== "button" && (
                        <Form.Label>{field.displayName}</Form.Label>
                      )}
                      {renderUserInputType(field, newOin[field.name])}
                    </Form.Group>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <div>
          <Modal.Footer>
            <Button onClick={() => saveOin()}>
            {idOin != 0 && idOin != undefined ? "Actualizar Oin" : "Guardar Oin"}
              <i className="fa-solid fa-floppy-disk"></i>
            </Button>
          </Modal.Footer>
        </div>
      </Form>
      
      {/* modal para cotizaciones en oin  */}
      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Quotes getQuote={quotes} getIdQuotes={saveIdQuatation} newServiceOin={newService}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ComponentOin;
