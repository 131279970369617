
import { useState } from "react";
import Form from "react-bootstrap/Form";
import FormUsers from "../components/formUser";

const Customer = () => {
  const [titleSend, setTitleSend] = useState();
  const [valueSend, setValue] = useState("");

  const selectUser = [
    { value: 0, label: "..." },
    { value: 1, label: "Persona Natural" },
    { value: 2, label: "Persona Jurídica" },
  ];

  const handleChangeUser = (event) => {
    setValue(event.target.value);
    if (event.target.value === "1") {
      setTitleSend("Persona Natural");
    } else if (event.target.value === "2") {
      setTitleSend("Persona Jurídica");
    }
  };

  //   templete
  return (
    <div className="dashboard">
      <div className="shadow rounded bg-white dashboard-container dashboard-inner-container">
        <div className="dashboard-header mb-5">
          <div className="col-md-5">
            <Form.Select onChange={handleChangeUser} name={selectUser.label}>
              {selectUser.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>

        <div className="certificates">
          <div className="dashboard-container">
            <legend>{titleSend}</legend>
            <FormUsers typeForm={valueSend} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
