import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'

const ModalComponent = ({ show, title, handleClose, handleClick, children, errors, showFooter,  handleEdit, isUpdate = false} ) => (
    <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {showFooter === '1' && (
            <Modal.Footer>
                {!!errors.length && (
                    <Alert className='w-100' variant='danger'>{errors}</Alert>
                )}
                    <Button variant='warning' onClick={handleClick}>
                        Guardar
                    </Button>
            </Modal.Footer>
        )}
        {showFooter === '2' && (
            <Modal.Footer>
                {!!errors.length && (
                    <Alert className='w-100' variant='danger'>{errors}</Alert>
                )}
    
                    <Button className="btn btn-danger" onClick={handleEdit}  >
                        Eliminar
                    </Button>
                     
                    <Button variant='warning' onClick={handleClick} disabled={isUpdate} >
                        Actualizar
                    </Button>
            </Modal.Footer>
        )} 
    </Modal>
)

export default ModalComponent