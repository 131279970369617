import React from 'react';
import { Modal } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageCarouselModal = ({ isOpen, onClose, images }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Imagenes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel>
          {images.map((image, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <img src={image} alt={`Image ${index}`} />
              <a href={image} target="_blank" className="btn btn-primary" style={{ position: 'absolute', top: 10, right: 30 }}>
                <i className="fa fa-search" aria-hidden="true"></i>
              </a>
            </div>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};

export default ImageCarouselModal;
