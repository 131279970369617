import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "../../css/dashboard.css";
import Table from "react-bootstrap/Table";
import ModalComponent from "../../components/Modal";
import Form from "react-bootstrap/Form";
import Loader from "../../components/Loader";
import {updateData, getData, postData, delateData} from "../../helpers/request";
import { useNavigate } from "react-router-dom";
import {USER_CERTIFICATE_FIELDS, GROUP_CERTIFICATE_FIELDS, HOST_NAME} from "../../constants";
import QRCode from "qrcode.react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fillDefaultValues } from "../../helpers/filler";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Swal from 'sweetalert2'
import _isEqual from 'lodash/isEqual';

const EMPTY_USER_CERTIFICATE = fillDefaultValues(USER_CERTIFICATE_FIELDS);

const EMPTY_GROUP_CERTIFICATE = fillDefaultValues(GROUP_CERTIFICATE_FIELDS);
let timerInput;

const Dashboard = () => {
  const navigation = useNavigate();
  /*
    ============================================
*/

  // const [filteredData, setFilteredData] = useState([]);
  // const [filterdDataGroup, setFilterDataGroup] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterValueGroup, setFilterValueGroup] = useState("");
  const [userCertificates, setUserCertificates] = useState([]);
  const [groupCertificates, setGroupCertificates] = useState([]);
  const [modalOpen, setModalOpen] = useState(null);
  const [modal, setModal] = useState(false);
  const [newUserCertificate, setNewUserCertificate] = useState(
    EMPTY_USER_CERTIFICATE
  );
  const [newGroupCertificate, setNewGroupCertificate] = useState(
    EMPTY_GROUP_CERTIFICATE
  );
  const [updateCertificate, setUpdateNewCertificate] = useState({});
  const [updateCertificateOrigin, setUpdateNewCertificateOrigin] = useState({});
  const [userErrors, setUserErrors] = useState("");
  const [groupErrors, setGroupErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQR, setSelectedQR] = useState(null);
  const [pages, setPages] = useState({
    user: 0,
    group: 0,
  });
  const [listCertificatesAboutExpireUser, setListCertificatesAboutExpireUser] = useState([]);

//  filtro por usuarios
  // const handleFilterChange = (event) => {
  //   const { value } = event.target;
  //   setFilterValue(value);
  //   const filtered = userCertificates.filter((item) =>
  //     item.document.includes(value) || item.name.toLowerCase().includes(value)
  // );
  //   setFilteredData(filtered);
  // };
  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    clearTimeout(timerInput);
    timerInput = setTimeout(() => {
      searchCertificates(value);
    }, 2000);
  };

  const getCertificatesAboutExpireUser = () => {
    getData({
      route: `getCertificatesAboutExpireUser`,
    })
    .then((response) => {
      if (response.success) {
        setListCertificatesAboutExpireUser(response.data);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  }
  
//  filtro por grupos
  // const filterGroup = (event) => {
  //   const { value } = event.target;
  //   setFilterValueGroup(value);
  //   const filtered = groupCertificates.filter((item) =>
  //     item.certificate.toLowerCase().includes(value) || item.name_group.toLowerCase().includes(value)
  //   );
  //   setFilterDataGroup(filtered);
  // };
  const filterGroup = (event) => {
    const { value } = event.target;
    setFilterValueGroup(value);
    clearTimeout(timerInput);
    timerInput = setTimeout(() => {
      searchCertificatesGroup(value);
    }, 2000);
  };

  const closeModals = () => {
    setModalOpen(null);
    setNewUserCertificate(EMPTY_USER_CERTIFICATE);
    setNewGroupCertificate(EMPTY_GROUP_CERTIFICATE);
    setUserErrors("");
    setGroupErrors("");
  };

  const openModal = (modal) => setModalOpen(modal);

  const editData = (dataUser) => {
    setUpdateNewCertificate(dataUser);
    setUpdateNewCertificateOrigin(dataUser)
    if (dataUser.name) {
      openModal("Edit")
    }else{
      openModal("EditGroup")
    }
    setModal(true)
  }

  const handleChangeUser = (event) => {
    setNewUserCertificate({
      ...newUserCertificate,
      [event.target.name]: event.target.value,
    });
  };
  const handleChange = (event) => {
    setUpdateNewCertificate({
      ...updateCertificate,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeGroup = (event) => {
    setNewGroupCertificate({
      ...newGroupCertificate,
      [event.target.name]: event.target.value,
    });
  };

  const handleUserDateChange = (date, fieldName) => {
    setNewUserCertificate({
      ...newUserCertificate,
      [fieldName]: date,
    });
  };

  const handleGroupDateChange = (date, fieldName) => {
    setNewGroupCertificate({
      ...newGroupCertificate,
      [fieldName]: date,
    });
  };

  const triggerUpdate = () => {
    setIsLoading(true);
    setUserCertificates([])
    setGroupCertificates([])
    Promise.all([
        getData({ route: `getAllCertificateUser&page=${pages.user}` }),
        getData({ route: `getAllCertificateGroup&page=${pages.group}` }),
    ])
      .then((responses) => {
        const [userResult, groupsResult] = responses;
        setUserCertificates(userResult.success ? userResult.data : []);
        setGroupCertificates(groupsResult.success ? groupsResult.data : []);
        // setFilteredData(userResult.data);
        // setFilterDataGroup(groupsResult.data);

        if (userResult.message === "invalid cookie") {
          navigation('/')
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchCertificates = (value) => {
    setIsLoading(true)
    if(value){
      postData({
          route: 'getCertificateByDocument',
          data: {
            value
          }
      }).then((response) => {
          setIsLoading(false)
          if(response.success) {
            setUserCertificates(response.data)
          }
          else {
              Swal.fire({
                  title: "No se encontraron datos de este registro",
                  icon: "warning"
              });
          }
      }).finally(() => {
          setIsLoading(false)
      })
    }else{
      triggerUpdate()
    }
}
  const searchCertificatesGroup = (value) => {
    setIsLoading(true)
    if(value){
      postData({
          route: 'getCertificateByName',
          data: {
            value
          }
      }).then((response) => {
          setIsLoading(false)
          if(response.success) {
            setGroupCertificates(response.data)
          }
          else {
              Swal.fire({
                  title: "No se encontraron datos de este registro",
                  icon: "warning"
              });
          }
      }).finally(() => {
          setIsLoading(false)
      })
    }else{
      triggerUpdate()
    }
    
}

  const saveUserCertificate = () => {
    setUserErrors("");
    let emptyFields = [];
    USER_CERTIFICATE_FIELDS.forEach((field) => {
      const value = newUserCertificate[field.name];
      if (!value.length && field.type !== "date") {
        emptyFields = [...emptyFields, field.displayName];
      }
    });

    if (emptyFields.length) {
      setUserErrors(`Llene los siguientes campos: ${emptyFields.join(", ")}`);
      return;
    }
    setIsLoading(true);
    postData({
      route: "createCertificatesUser",
      data: newUserCertificate,
    })
      .then((response) => {
        if (response.success) {
          Swal.fire({
            position: "position-absolute bottom-0 end-0",
            icon: "success",
            title: "Guardado correctamente",
            showConfirmButton: false,
            timer: 1500
          });
          triggerUpdate();
          closeModals();
        } else {
          Swal.fire({
            title: "Error",
            text: response.message,
            icon: "warning"
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error",
          text: e.message,
          icon: "warning"
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveGroupCertificate = () => {
    setGroupErrors("");
    let emptyFields = [];
    GROUP_CERTIFICATE_FIELDS.forEach((field) => {
      const value = newGroupCertificate[field.name];
      if (!value.length && field.type !== "date") {
        emptyFields = [...emptyFields, field.displayName];
      }
    });

    if (emptyFields.length) {
      setGroupErrors(`Llene los siguientes campos: ${emptyFields.join(", ")}`);
      return;
    }

    setIsLoading(true);
    postData({
      route: "createCertificatesGroup",
      data: newGroupCertificate,
    })
      .then((response) => {
        if (response.success) {
          Swal.fire({
            position: "position-absolute bottom-0 end-0",
            icon: "success",
            title: "Guardado correctamente",
            showConfirmButton: false,
            timer: 1500
          });
          triggerUpdate();
          closeModals();
        } else {
          Swal.fire({
          title: "Error",
          text: response.message,
          icon: "warning"
        });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error",
          text: e.message,
          icon: "warning"
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editUserCertificate = () => {
    const isEqual = _isEqual(updateCertificateOrigin, updateCertificate);
    if(!isEqual){
      setIsLoading(true);
      updateData({
        route: "updateCertificatesUser",
        data: updateCertificate,
      })
        .then((response) => {
          if (response.success) {
            Swal.fire({
              title: "Actualizado correctamente",
              icon: "success"
            });
            triggerUpdate();
            closeModals();
          } else {
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "warning"
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            title: "Error",
            text: e.message,
            icon: "warning"
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  
  const editGroupCertificate = () => {
    const isEqual = _isEqual(updateCertificateOrigin, updateCertificate);
    if(!isEqual){
      setIsLoading(true);
      updateData({
        route: "updateCertificatesGroup",
        data: updateCertificate,
      })
        .then((response) => {
          if (response.success) {
            triggerUpdate();
            closeModals();
          } else {
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "warning"
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            title: "Error",
            text: e.message,
            icon: "warning"
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const deleteCertificate = () => {
    let setRouterDelete = null
    if (updateCertificate.name_group){
      setRouterDelete = `deleteCertificateGroup&id=`
    }else{
      setRouterDelete = `deleteCertificatesUser&id=`
      
    }
    Swal.fire({
      title: "Eliminar",
      text: "Deseas eliminar este registro",
      icon: "warning",
      cancelButtonText: "No",
      confirmButtonText: "Si",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33"
    }).then((result) => {
      if (result.isConfirmed) {
        
        delateData({
          route: setRouterDelete + updateCertificate.id,
          data: updateCertificate,
        })
          .then((response) => {
            if (response.success) {
              Swal.fire({

                title: "Deleted!",
                text: "Eliminado Correctamente",
                icon: "success"
              });
              triggerUpdate();
              closeModals();
            } else {
              Swal.fire({
                title: "Error",
                text: response.message,
                icon: "warning"
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: "Error",
              text: "No se pudo borrar",
              icon: "warning"
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  }

  useEffect(() => {

    triggerUpdate();
    getCertificatesAboutExpireUser();
  
    }, [pages]);

  const renderUserInputType = (field) => {
    switch (field.type) {
      case "date":
        return (
          <DatePicker
            className="form-control"
            selected={newUserCertificate[field.name]}
            onChange={(date) => handleUserDateChange(date, field.name)}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
          />
        );

      case "select":
        return (
          <Form.Select onChange={handleChangeUser} name={field.name}>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={newUserCertificate[field.name]}
            onChange={handleChangeUser}
          />
        );
    }
  };

  const renderGroupInputType = (field) => {
    switch (field.type) {
      case "date":
        return (
          <DatePicker
            className="form-control"
            selected={newGroupCertificate[field.name]}
            onChange={(date) => handleGroupDateChange(date, field.name)}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
          />
        );

      case "select":
        return (
          <Form.Select onChange={handleChangeGroup} name={field.name}>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={newGroupCertificate[field.name]}
            onChange={handleChangeGroup}
          />
        );
    }
  };

  const renderUpdateInputType = (field) => {
    switch (field.type) {
      case "select":
        return (
          <Form.Select onChange={handleChange} name={field.name}>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return (
          <Form.Control
            type={field.type}
            name={field.name}
            value={updateCertificate[field.name]}
            onChange={handleChange}
          >  
          </Form.Control>
        );
    }
  };

  const showSwallTable = () => {
    var tableHTML = '<table class="table table-sm table-striped">' +
                    '<thead>' +
                    '<tr>' +
                    '<th>ID</th>' +
                    '<th>Documento</th>' +
                    '<th>Nombre</th>' +
                    '<th>Validez</th>' +
                    '<th>Estado</th>' +
                    '</tr>' +
                    '</thead>' +
                    '<tbody>';
    listCertificatesAboutExpireUser.forEach(function(documento) {
        tableHTML += '<tr>' +
                    '<td>' + documento.id + '</td>' +
                    '<td>' + documento.document + '</td>' +
                    '<td>' + documento.name + '</td>' +
                    '<td>' + documento.validity + '</td>' +
                    '<td>' + documento.status + '</td>' +
                    '</tr>';
    });
    tableHTML += '</tbody></table>';

    Swal.fire({
      html: tableHTML,
      customClass: {
          container: 'swal-container',
          popup: 'swal-popup',
          content: 'swal-content',
          confirmButton: 'swal-confirm-button',
          cancelButton: 'swal-cancel-button'
      }
    });
  }


  return (
    <div className="dashboard">
      {isLoading && <Loader />}
      <ModalComponent
        show={selectedQR}
        title={`Consulta de certificado para ${
          selectedQR?.name || selectedQR?.name_group
        }`}
        handleClose={() => setSelectedQR(null)}
        errors=""
        showFooter=''
      >
        <div className="d-flex justify-content-center">
          <QRCode
            renderAs="canvas"
            level="H"
            includeMargin={true}
            value={
              selectedQR?.document
                ? `${HOST_NAME}/#/consulta-certificados-persona?document=${selectedQR?.document}&code=${selectedQR?.verification_code}`
                : `${HOST_NAME}/#/consulta-certificados-equipo?group=${selectedQR?.certificate}`
            }
            size={256}
          />
        </div>
      </ModalComponent>
      <div className="shadow rounded bg-white dashboard-container dashboard-inner-container">
        <div className="certificates">
          <div className="dashboard-container">
            <div className="certificates-header">
              <h4>Certificados por persona</h4>

              <div className="row justify-content-end">
                <div className="col-8">
                  <div className="input-group mb-3">
                    <div className="ml-2 position-relative" style={{ marginRight: "15px" }}>
                      <Button
                        onClick={() => showSwallTable()}
                        variant="warning"
                        className="rounded-circle"
                        style={{ width: '40px', height: '40px', padding: 0}}
                      >
                        <i className="fa fa-bell" aria-hidden="true"></i>
                      </Button>
                      <span className="badge bg-danger position-absolute top-0 start-100 translate-middle p-2 rounded-circle">
                        {listCertificatesAboutExpireUser.length}
                      </span>
                    </div>
                    <span className="input-group-text" onClick={() => searchCertificates(filterValue)}>
                      <i className="fa fa-search" aria-hidden="true" ></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={filterValue}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <Button onClick={() => openModal("USER")} variant="warning">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            </div>

            {/* modal paraCrear usuarios  /*
            ============================================
          */}
            <ModalComponent
              show={modalOpen === "USER"}
              title="Agregar nuevo certificado individual"
              handleClose={closeModals}
              handleClick={saveUserCertificate}
              errors={userErrors}
              showFooter="1"
            >
              <Form>
                {USER_CERTIFICATE_FIELDS.map((field, index) => (
                  <Form.Group key={index} className="mb-2">
                    <Form.Label>{field.displayName}</Form.Label>
                    {renderUserInputType(field)}
                  </Form.Group>
                ))}
              </Form>
            </ModalComponent>
            {/* ============================================*/}

            {/* modal para editar usuarios  /*
            ============================================
          */}
            <div >
            {modal ? (
            <ModalComponent
              show={modalOpen === "Edit"}
              title="Editar certificado "
              handleClose={closeModals}
              handleClick={editUserCertificate}
              handleEdit={deleteCertificate}
              errors={userErrors}
              showFooter="2"
              isUpdate={_isEqual(updateCertificateOrigin, updateCertificate)}
            >
              <Form>
                {USER_CERTIFICATE_FIELDS.map((field, index) => (
                  <Form.Group key={index} className="mb-2">
                    <Form.Label>{field.displayName}</Form.Label>
                    {renderUpdateInputType(field)}
                  </Form.Group>
                ))}
              </Form>
            </ModalComponent>
            ) : null}
            </div>
            
          {/* ============================================*/}
            {/* modal para editar grupos  /*
            ============================================
          */}
            <div >
            {modal ? (
            <ModalComponent
              show={modalOpen === "EditGroup"}
              title="Editar certificado grupal "
              handleClose={closeModals}
              handleClick={editGroupCertificate}
              handleEdit={deleteCertificate}
              errors={userErrors}
              showFooter="2"
              isUpdate={_isEqual(updateCertificateOrigin, updateCertificate)}
            >
              <Form>
                {GROUP_CERTIFICATE_FIELDS.map((field, index) => (
                  <Form.Group key={index} className="mb-2">
                    <Form.Label>{field.displayName}</Form.Label>
                    {renderUpdateInputType(field)}
                  </Form.Group>
                ))}
              </Form>
            </ModalComponent>
            ) : null}
            </div>
          {/* ============================================*/}

            <Table bordered hover variant="dark">
              <thead>
                <tr>
                  {USER_CERTIFICATE_FIELDS.map((field, index) => (
                    <th key={index}>{field.displayName}</th>
                  ))}
                  <th>Opción</th>
                  <th>QR</th>
                </tr>
              </thead>
              <tbody className="custom-table">
                {userCertificates.map((value, index) => (
                  <tr key={index}>
                    <td>{value?.name}</td>
                    <td>{value?.document}</td>
                    <td>{value?.scope}</td>
                    <td>{value?.ability}</td>
                    <td>{value?.rule}</td>
                    <td>{value?.verification_code}</td>
                    <td>{value?.expedition}</td>
                    <td>{value?.validity}</td>
                    <td>{value?.state}</td>
                    <td>{value?.option}
                    <Button variant="warning" onClick={() => editData(value)}>
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                    </Button>
                    </td>
                    <td>
                      <Button
                      
                        onClick={() => setSelectedQR(value)}
                        variant="warning"
                      >
                       <i className="fa fa-qrcode" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="paginator">
              <Button
                onClick={() => setPages({ ...pages, user: --pages.user })}
                disabled={pages.user === 0}
                variant="warning"
              >
                {"<"}
              </Button>
              <p>Página {pages.user + 1}</p>
              <Button
                onClick={() => setPages({ ...pages, user: ++pages.user })}
                variant="warning"
              >
                {">"}
              </Button>
            </div>
          </div>

          {/* certificados de grupo */}

          <div className="dashboard-container">
            <div className="certificates-header">
              <h4>Certificados por equipo</h4>
                <div className="row justify-content-end">
                  <div className="col-6">
                    <div className="input-group mb-3">
                      <span className="input-group-text" onClick={() => searchCertificatesGroup(filterValueGroup)}>
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={filterValueGroup}
                        onChange={filterGroup}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <Button onClick={() => openModal("GROUP")} variant="warning">
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
              <ModalComponent
                show={modalOpen === "GROUP"}
                title="Agregar nuevo certificado grupal"
                handleClose={closeModals}
                handleClick={saveGroupCertificate}
                errors={groupErrors}
                showFooter='1'
              >
                <Form>
                  {GROUP_CERTIFICATE_FIELDS.map((field, index) => (
                    <Form.Group key={index} className="mb-2">
                      <Form.Label>{field.displayName}</Form.Label>
                      {renderGroupInputType(field)}
                    </Form.Group>
                  ))}
                </Form>
              </ModalComponent>
            </div>
            <Table bordered hover variant="dark">
              <thead>
                <tr>
                  {GROUP_CERTIFICATE_FIELDS.map((field, index) => (
                    <th key={index}>{field.displayName}</th>
                  ))}
                  <th>Opción</th>
                  <th>QR</th>
                </tr>
              </thead>
              <tbody className="custom-table">
                {groupCertificates.map((value, index) => (
                  <tr key={index}>
                    <td>{value?.certificate}</td>
                    <td>{value?.name_group}</td>
                    <td>{value?.type}</td>
                    <td>{value?.rule}</td>
                    <td>{value?.brand}</td>
                    <td>{value?.serie}</td>
                    <td>{value?.model}</td>
                    <td>{value?.expedition_date}</td>
                    <td>
                    <Button 
                      onClick={() => editData(value)}
                      variant="warning"
                    >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                    </Button>
                    </td>
                    <td>
                      <Button
                        onClick={() => setSelectedQR(value)}
                        variant="warning"
                      >
                       <i className="fa fa-qrcode" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="paginator">
              <Button
                onClick={() => setPages({ ...pages, group: --pages.group })}
                disabled={pages.group === 0}
                variant="warning"
              >
                {"<"}
              </Button>
              <p>Página {pages.group + 1}</p>
              <Button
                onClick={() => setPages({ ...pages, group: ++pages.group })}
                variant="warning"
              >
                {">"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
