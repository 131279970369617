import Loader from "../components/Loader";
import { useState, useEffect } from "react";
import ComponentCreateOcp from "../components/componentOcp";
import { Modal, Button, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { getData } from "../helpers/request";
import { TABLE_OCP } from "../constants";

const Ocp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ocpData, setOcp] = useState({});
  const [allOcp, getAllOcp] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [pages, setPages] = useState({
    paginado: 0,
  });
  const [timerInput, setTimerInput] = useState(null);

  const handleShow = () => {
    getDataTable();
    setShow(true);
  }


  const getDataTable = () => {

    getData({
      route: `listOcpRecord&page=${pages.paginado}`,
    })
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
            getAllOcp(response.data);
        } else {
          Swal.fire({
            title: "No se encontraron datos de este registro",
            icon: "warning",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    clearTimeout(timerInput);
    const newTimer = setTimeout(() => {
      searchOcp(value);
    }, 1000);
    setTimerInput(newTimer);
  };

  const searchOcp = (value) => {
    if (value) {
      getData({
        route: `getOcpRecordById&id=${value}`,
      })
        .then((response) => {
          setIsLoading(false);
          if (response.success) {
            response.data.idOcp = value
            setOcp(response.data);
          } else {
            Swal.fire({
              title: "No se encontraron datos de este registro",
              icon: "warning",
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
        setOcp();
    }
  };

  useEffect(() => {
      getDataTable();
   
  }, [pages]);

  return (
    <div className="dashboard">
      {isLoading && <Loader />}
      <div className="shadow rounded bg-white dashboard-container dashboard-inner-container">
        <div className="dashboard-header mb-5">
          <h2>OCP</h2>
          <Button onClick={handleShow}>
            Ver OCP&nbsp;
            <i className="fa fa-search" aria-hidden="true"></i>
          </Button>
          <div className="row justify-content-end">
            <h6>BUSCAR OCP POR NUMERO </h6>
            <div className="input-group mb-3">
              <span
                className="input-group-text"
                onClick={() => searchOcp(filterValue)}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
              <input
                type="text"
                className="form-control"
                value={filterValue}
                onChange={handleFilterChange}
              />
            </div>
          </div>
        </div>
        <div className="certificates">
          <div className="dashboard-container">
            <ComponentCreateOcp getOcp={ocpData} />
          </div>


          <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header closeButton>
              <Modal.Title>OCP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover className="table">
                <thead>
                  <tr>
                    {TABLE_OCP.map((field, index) => (
                      <th key={index}>{field.displayName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                {allOcp.map((value, index) => (
                  <tr key={index}>
                    <td>{value.id}</td>
                    <td>{value.nameClient}</td>
                    <td>{value.document}</td>
                    <td>{value.evaluationLocation}</td>
                    <td>{value.createdAt}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <div className="paginator">
                <Button
                  onClick={() =>
                    setPages({ ...pages, paginado: --pages.paginado })
                  }
                  disabled={pages.paginado === 0}
                  variant="warning"
                >
                  {"<"}
                </Button>
                <p>Página {pages.paginado + 1}</p>
                <Button
                  onClick={() =>
                    setPages({ ...pages, paginado: ++pages.paginado })
                  }
                  variant="warning"
                >
                  {">"}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Ocp;
