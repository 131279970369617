// import varConDataUsers from './components/componentOin'

export const USER_CERTIFICATE_FIELDS = [{
    name: 'name',
    displayName: 'Nombres y apellidos',
    type: 'text'
    }, {
    name: 'document',
    displayName: 'Documento',
    type: 'number'
    }, {
    name: 'scope',
    displayName: 'Alcance',
    type: 'text'
    }, {
    name: 'ability',
    displayName: 'Capacidad',
    type: 'text'
    }, {
    name: 'rule',
    displayName: 'Norma',
    type: 'text'
    }, {
    name: 'verification_code',
    displayName: 'Código de verificación',
    type: 'text'
    }, {
    name: 'expedition',
    displayName: 'Fecha expedición',
    type: 'date'
    }, {
    name: 'validity',
    displayName: 'Vigencia',
    type: 'date'
    }, {
    name: 'state',
    displayName: 'Estado',
    type: 'select',
    options: ['Activo', 'Suspendido', 'Retirado']
    }, 
]

export const GROUP_CERTIFICATE_FIELDS = [{
    name: 'certificate',
    displayName: 'Certificado',
    }, {
    name: 'name_group',
    displayName: 'Nombre de equipo',
    }, {
    name: 'type',
    displayName: 'Tipo',
    }, {
    name: 'rule',
    displayName: 'Norma',
    }, {
    name: 'brand',
    displayName: 'Marca',
    }, {
    name: 'serie',
    displayName: 'Serie',
    }, {
    name: 'model',
    displayName: 'Modelo',
    }, {
    name: 'expedition_date',
    displayName: 'Fecha expedición',
    type: 'date'
    },
]
export const CREATE_CUSTOMER = [
    {
        name: 'documentType',
        displayName: 'Tipo de documento',
        type: 'select',
        options: ['...', 'C.C', 'C.E']
    },
    {
        name: 'document',
        displayName: 'N° de Documento',
        type: 'number',
        stile:"col-md-6",
    },
    {
        name: 'name',
        displayName: 'Nombres y apellidos',
        type: 'text',
        stile:"col-md-9",
        disable: 1        
    },  
    {
        name: 'mail',
        displayName: 'Correo Electrónico',
        type: 'text',
        stile:"col-md-6",
        disable: 1        
    },  
    {
        name: 'phone',
        displayName: 'N° Celular',
        type: 'number',
        stile:"col-md-6",
        disable: 1        
    },  
    {
        name: 'address',
        displayName: 'Dirección',
        type: 'text',
        stile:"col-md-6",
        disable: 1        
    }, 
     
]
export const CREATE_CUSTOMER_JURIDIC = [
    {
        name: 'document',
        displayName: 'Nit',
        type: 'number',
    },
    {
        name: 'name',
        displayName: 'Razón social',
        type: 'text'
    },
    {
        name: 'contactOne',
        displayName: 'Contacto 1',
        type: 'text'
    },  
    {
        name: 'contactOnePhone',
        displayName: 'N° Celular contacto 1',
        type: 'number'
    }, 
    {
        name: 'contactTwo',
        displayName: 'Contacto 2',
        type: 'text'
    }, 
    {
        name: 'contactTwoPhone',
        displayName: 'N° Celular 2',
        type: 'number'
    },  
    {
        name: 'mail',
        displayName: 'Correo Electrónico',
        type: 'text'
    },  
     
    {
        name: 'address',
        displayName: 'Dirección',
        type: 'text'
    },   
     
]
export const CREATE_QUOTES = [
    {
        name: 'documentType',
        displayName: 'Tipo de documento',
        type: 'text',
        stile:"col-md-9",
        disable: 1        
    },
    {
        name: 'document',
        displayName: 'N° de Documento',
        type: 'number',
        stile:"col-md-6",
    },
    {
        name: 'name',
        displayName: 'Nombres y apellidos',
        type: 'text',
        stile:"col-md-9",
        disable: 1        
    },
    {
        name: 'mail',
        displayName: 'Correo Electrónico',
        type: 'text',
        stile:"col-md-6",
        disable: 1        
    },  
    {
        name: 'phone',
        displayName: 'N° Celular',
        type: 'number',
        stile:"col-md-6",
        disable: 1        
    },  
    {
        name: 'address',
        displayName: 'Dirección',
        type: 'text',
        stile:"col-md-6",
        disable: 1        
    }, 
     
]
export const CREATE_QUOTES_ADMIN = [
    
    {
        name: 'nameSignature',
        displayName: 'Nombre',
        type: 'text',
        stile:"col-md-6",
    },
    {
        name: 'charges',
        displayName: 'Cargo',
        type: 'text',
        stile:"col-md-6",
    },
     
]
export const HEADER_TABLE_QUOTES = [
    {
        name: 'item',
        displayName: 'ITEM',
    },
    {
        name: 'description',
        displayName: 'SERVICIO',
    },
    {
        name: 'descriptionExtra',
        displayName: 'DESCRIPCION',
    },
    {
        name: 'cantVR',
        displayName: 'CANTIDAD $V/R',
       
    },
    {
        name: 'cantUnit',
        displayName: 'UNITARIO $V/R',
       
    },
    {
        name: 'total',
        displayName: 'TOTAL',
       
    },
     
]
export const HEADER_GET_QUOTES = [
    
    {
        name: 'id',
        displayName: 'Id',
       
    },
    {
        name: 'nameClient',
        displayName: 'Nombre cliente',
       
    },
    {
        name: 'createdAt',
        displayName: 'Creado el dia',
       
    },
    {
        name: 'signatur',
        displayName: 'firmado por',
       
    },
     
]
export const DATA_TABLE = [
    {
        content: [
            { name: 'item',
            displayName: 'ITEM',
            type: 'number',
            value:1,
            },
            {
                name: 'description',
                displayName: 'DESCRIPCION DEL SERVICIO',
                type: 'select',
                options:['Inspección con fines de certificado', ' Proceso de evaluación con fines',
                    'Inspección por medio de tintas'
                ]
            },
            {
                name: 'cantVR',
                displayName: 'CANTIDAD $V/R',
                type: 'number',
                value:0
            },
            {
                name: 'cantUnit',
                displayName: 'UNITARIO $V/R',
                type: 'number',
                value:0
            },
            {
                name: 'total',
                displayName: 'TOTAL',
                type: 'number',
                value:0
            },
        ]
    },
    
     
]
export const CREATE_OCP = [ 
    {
        name: 'idQuotation',
        displayName: 'ID Cotización',
        type: 'number',
        stile:"col-md-2",
        disable: 0
    },
    {
        name: 'documentType',
        displayName: 'Tipo de documento',
        type: 'text',
        stile:"col-md-3",
        disable: 1
    },
    {
        name: 'identificationDocument',
        displayName: 'N° de Documento',
        type: 'number',
        stile:"col-md-6",
        disable: 0
    },
    {
        name: 'name',
        displayName: 'Nombres y apellidos',
        type: 'text',
        stile:"col-md-9",
        disable: 1
    },  
    {
        name: 'mail',
        displayName: 'Correo Electrónico',
        type: 'text',
        stile:"col-md-6",
        disable: 1
    },  
    {
        name: 'phone',
        displayName: 'N° Celular',
        type: 'number',
        stile:"col-md-6",
        disable: 1
    },
    {
        name: 'specialNeeds',
        displayName: 'Presenta alguna necesidad especial',
        type: 'select',
        options: ['Si', 'No'],
        stile:"col-md-4",
        disable: 1
    },
    {
        name: 'specialNeedsText',
        displayName: 'indique cuál',
        type: 'text',
        options: ['Si', 'No'],
        stile:"col-md-4",
        validation: true
    },
    {
        name: 'address',
        displayName: 'Dirección de notificación',
        type: 'text',
        stile:"col-md-6",
        disable: 1
    },  
    {
        name: 'cityDepartment',
        displayName: 'Ciudad y Departamento',
        type: 'text',
        stile:"col-md-6",
        disable: 1
    },  
    {
        name: 'evaluationLocation',
        displayName: 'Lugar de evaluación',
        type: 'text',
        stile:"col-md-8"
    }, 
    {
        name: 'date',
        displayName: 'Fecha',
        type: 'date',
        stile:"col-md-3"
    }, 
    {
        name: 'requestType',
        displayName: 'Tipo de solicitud',
        type: 'select',
        options: ['Otorgamiento', 'Seguimiento 1', 'Seguimiento 2', 'Renovación'],
        stile:"col-md-4"
    },
    {
        name: 'scope',
        displayName: 'Alcance',
        type: 'select',
        options: [
            'Supervisor de izajes de carga(norma ASME B30.5-2021)',
            'Operador de grúas móviles y locomotoras (norma ASME B30.5-2021)',
            'Aparejador/Señalero de izajes de carga(norma ASME B30.9-2021)',
            'Operador de grúa de brazo articulado (norma ASME B30.22-2016)',
            'Operador de montacargas y apiladores (norma ANSI/ITSDF B56.1-2020)',
            'Operador de cargador (norma OSHA 1910.178)',
            'Operador de excavadora hidráulica y retrocargador (norma NSCL 270101120)',
            'Aparejador/Señalero de izaje de cargas (norma ASME B30.9-2021)',
            'Supervisor de izaje de cargas (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Telescópica sobre llantas múltiples estaciones de control (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Telescópica sobre llantas única estación de control (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Telescópica sobre camión comercial  (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Telescópica sobre orugas única estación de control  (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Telescópica sobre llantas única estación de control  (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Celosía sobre llantas múltiples estaciones de control (norma ASME B30.5-2021)',
            'Operador de Grúa Móvil y Locomotora - Celosía sobre orugas única estación de control (norma ASME B30.5-2021)',
            'Operador de Grúa de Brazo Articulado (norma ASME B30.22-2016)',
            'Operador de Montacargas y Apiladores - Contrabalanceado carga lateral/frontal (norma ANSI/ITSDF B56.1-2020)',
            'Operador de Montacargas y Apiladores - Contrabalanceado operador de pie (norma ANSI/ITSDF B56.1-2020)',
            'Operador de Montacargas y Apiladores - Contrabalanceado cuatro (4) ruedas (norma ANSI/ITSDF B56.1-2020)',
            'Operador de Montacargas y Apiladores - Retráctil (norma ANSI/ITSDF B56.1-2020)',
            'Operador de Cargador (norma OSHA 1926.602 - OSHA 1910.178)',
            'Operador de Excavadora Hidráulica para izaje de carga (norma NSCL 270101120)',
            'Operador de Retrocargador (norma NSCL 270101120)',
        ],
        stile:"col-md-9"
    },
    {
        name: 'scopeType',
        displayName: 'tipo',
        type: 'select',
        options: [
            'Telescópica sobre llantas (múltiples estaciones de control)',
            'Telescópica sobre camión comercial',
            'Telescópica sobre orugas (única estación de control)',
            'Telescópica sobre llantas (única estación de control)',
            'Celosía sobre llantas (múltiples estaciones de control)',
            'Celosía sobre orugas (única estación de control)'
        ],
        stile:"col-md-4",
        display:"none"
    },
    {
        name: 'scopeTypeTwo',
        displayName: 'tipo',
        type: 'select',
        options: [
            'Contrabalanceado carga lateral/frontal',
            'Contrabalanceado Cuatro (4) ruedas',
            'Contrabalanceado Operador de pie',
            'Retráctil',
        ],
        stile:"col-md-4"
    },
    {
        name: 'capacity',
        displayName: 'Capacidad',
        type: 'text',
        stile:"col-md-6"
    },  
    {
        name: 'capacityType',
        displayName: 'Tipo de capacidad',
        type: 'select',
        options: ['kg', 'lb', 't'],
        stile:"col-md-4"
    },  
     
]
export const SELECT_FROM_BUY = [
{
    name: 'selectFormBuy',
    displayName: 'FORMA DE PAGO',
    type: 'select',
    options: ['De contado', 'Radiación Factura', '30 días radiación Factura', '50%  anticipado y 50% Radiación Factura'],
   
},  
]
export const VALIDATE_DAY = [
{
    name: 'validateDay',
    displayName: 'Validez',
    type: 'TEXT',
   
},  
]

export const ACCORDION_OCP = [
    {
        title: 'PRERREQUISITOS',
        content: [
            {
            name: 'addDocumentCc',
            displayName: ' ADJUNTAR DOCUMENTO DE IDENTIDAD',
            textInfo: "(solo se permite un pdf)",
            type: 'file',
            stile:"col-md-4",
            filName: "documento",
            typeFile: "pdf"
            }, 
            {
            name: 'addLicense',
            displayName: 'ADJUNTAR LICENCIA DE CONDUCCIÓN',
            textInfo: "(solo se permite un pdf)",
            type: 'file',
            stile:"col-md-4",
            filName: "licencia",
            typeFile: "pdf"
            },
            {
            name: 'addCertificateHeights',
            displayName: ' ADJUNTAR CERTIFICADO TRABAJO EN ALTURAS',
            textInfo: "(solo se permite un pdf)",
            type: 'file',
            stile:"col-md-4",
            filName: "certificadoAlturas",
            typeFile: "pdf"
            },
            {
            name: 'certificateRequestPre',
            displayName: 'Solicitud de certificado',
            textInfo: "(solo se permite un pdf)",
            type: 'file',
            stile:"col-md-4",
            filName: "certificateRequestPre",
            typeFile: "pdf"
            },
            
        ]
    }, 
    {
        title: 'PROCESO DE CERTIFICACIÓN',
        content: [{
            name: 'linkEvaluationOne',
            displayName: 'EVALUACIÓN TEÓRICA',
            textInfo: "(solo se permite un pdf)",
            type: 'button',
            stile:"col-md-6"
        }, 
        {
            name: 'linkEvaluationTwo',
            displayName: 'LISTA DE CHEQUE PRUEBA PRACTICA',
            type: 'button',
            stile:"col-md-6"
        },
        {
            name: 'photos',
            displayName: 'SUBIR REGISTRO PRUEBA TEÓRICA',
            textInfo: "(solo se permite un pdf)",
            type: 'file',
            stile:"col-md-4",
            filName: "theorical_evaluation",
            typeFile: "pdf"
        },
        {
            name: 'photos',
            displayName: 'SUBIR REGISTRO FOTOGRÁFICO PRUEBA PRACTICA',
            textInfo: "",
            type: 'file',
            stile:"col-md-4",
            filName: "fotoRegistrosPruebaPractica",
            typeFile: "png"
        },
        {
            name: 'uploadChecklist',
            displayName: 'SUBIR LISTA DE CHEQUEO',
            textInfo: "",
            type: 'file',
            stile:"col-md-4",
            filName: "uploadChecklist",
            typeFile: "pdf"
        },
        
    ]
    }, 
    {
        title: 'REVISION Y TOMA DE DECISIÓN',
        content: [
        {
            name: 'theoretical',
            displayName: ' VER EXAMEN TEORICO CALIFICADO SEGUN RESPUESTAS',
            type: 'button',
            stile:"col-md-4"
        }, 
        {
            name: 'viewPre',
            displayName: 'VER PRERREQUISITOS',
            type: 'button',
            stile:"col-md-4"
        },
        {
            name: 'viewCheck',
            displayName: 'VER REGISTRO FOTOGRAFICO',
            type: 'button',
            stile:"col-md-4"
        },
        {
            name: 'uploadChecklist',
            displayName: 'VER CALIFICACION LISTA DE CHEQUEO',
            type: 'button',
            stile:"col-md-4"
        },        
        {
            name: 'certificateRequestPre',
            displayName: 'VER SOLICITUD DE CERTIFICADO',
            type: 'button',
            stile:"col-md-4"
        },        
        {
            name: 'identityDocumentCheck',
            displayName: 'Documento de identificación (cedula de ciudadanía o cédula de extranjería y/o pasaporte, y salvoconducto emitido por Migración Colombia)',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'scopeMatchesRequest',
            displayName: 'El alcance corresponde al solicitado',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'drivingLicenseC2',
            displayName: 'Licencia de conducción C2',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'structuredInterviewAndProfessionalDevelopmentRenewal',
            displayName: 'Entrevista estructurada y desarrollo profesional (renovación).',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'acceptCertificationContract',
            displayName: 'Acepto contrato de certificación',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'theoreticalExamScoreEqual',
            displayName: 'Calificación examen teorico igual o mayor al 88%',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'inPersonExam',
            displayName: 'Examen en forma presencial',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'remoteExam',
            displayName: 'Examen en forma remota',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'remoteExamLocation',
            displayName: 'Si el examen fue remoto indique sitio',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'remoteExamEffective',
            displayName: 'Examen remoto fue eficaz',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'practicalExamScoreEqualOrHigher',
            displayName: ' Calificación examen practico igual o superior a 88%',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'practicalExamChecklist',
            displayName: 'Lista de chequeo examen práctico',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'practicalExamPhotoEvidence',
            displayName: 'Registro fotográfico que evidencie el desarrollo del examen',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'fraudIndicators',
            displayName: 'Indicios de fraude',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4"
        },
        {
            name: 'certificationDecision',
            displayName: 'DECISION DE LA CERTIFICACIÓN',
            type: 'select',
            options: ['Otorgar la certificación', 'Mantener la certificación',
             'Renovar la certificación','Retirar la certificación ', 'No otorgar la certificación',
            'No mantener la certificación', 'Suspender certificación'],
            stile:"col-sm-6 col-md-8"
        },
    ]
    }, 
    {
        title: 'GENERAR CERTIFICADO',
        content: [{
            name: 'generateCertificate',
            displayName: ' GENERAR CERTIFICADO ',
            type: 'button',
            stile:"col-md-4"
        }, 
        {
            name: 'certificationStatus',
            displayName: 'ESTADO CERTIFICADO',
            type: 'select',
            options: ['EN PROCESO (requisitos pendiente)', 'PENDIENTE DE ENVIÓ', 'ENVIADO'],
            stile:"col-md-4"
        },]
    }
     
]
export const TABLE_OCP = [
    {
        name: 'id',
        displayName: 'Id',
       
    },
    {
        name: 'nameClient',
        displayName: 'Nombre del cliente',
       
    },
    {
        name: 'document',
        displayName: 'CC cliente',
       
    },
    {
        name: 'evaluationLocation',
        displayName: 'Lugar de evaluación',
       
    },
    {
        name: 'createdAt',
        displayName: 'Creado el:',
       
    },
]
export const TABLE_OIN = [
    {
        name: 'id',
        displayName: 'Id',
       
    },
    {
        name: 'nameClient',
        displayName: 'Nombre del cliente',
       
    },
    {
        name: 'requestType',
        displayName: 'Tipo de inspección',
       
    },
    {
        name: 'requiredInspectionScope',
        displayName: 'Alcance de inspección',
       
    },
    {
        name: 'createdAt',
        displayName: 'Creado el:',
       
    },
]

export const CREATE_OIN = [
    {
        name: 'date',
        displayName: 'Fecha',
        type: 'date',
        stile:"col-md-3"
    }, 
    {
        name: 'documentType',
        displayName: 'Tipo de documento',
        type: 'text',
        stile:"col-md-3",
        disable: 1
    },
    {
        name: 'document',
        displayName: 'N° de Documento',
        type: 'number',
        stile:"col-md-6"
    },
    {
        name: 'name',
        displayName: 'Nombres / Razón social',
        type: 'text',
        stile:"col-md-9",
        disable: 1
    },  
    {
        name: 'contactOne',
        displayName: 'Persona de contacto',
        type: 'text',
        stile:"col-md-6",
        disable: 1
    },
    {
        name: 'contactonePhone',
        displayName: 'N° Celular',
        type: 'number',
        stile:"col-md-6",
        disable: 1
    },
    {
        name: 'requestType',
        displayName: 'TIPO DE SOLICITUD',
        type: 'select',
        options: ['INSPECCIÓN INICIAL', 'INSPECCIÓN COMPLEMENTARIA'],
        stile:"col-md-8"
    },  
    {
        name: 'requiredInspectionScope',
        displayName: 'ALCANCE DE INSPECCIÓN REQUERIDO',
        type: 'select',
        options: [
            'Inspección de Grúa móvil y locomotoras ', 
            'Inspección de grilletes y herrajes ajustables',
            'Inspección de eslingas',
            'Inspección de grúa de brazo articulado',
            'Inspección de ganchos',
            'Inspección de Montacargas y Apiladores',
            'Inspección de equipos para movimiento de tierra',
            'Inspección por medio de ensayos no destructiva'
        ],
        stile:"col-md-8"
    },  
     
]

export const CREATE_OIN_UBICATION = [
    {
        name: 'address',
        displayName: 'Dirección',
        type: 'text',
        stile:"col-md-6"
    },
    {
        name: 'additionalAddressReported',
        displayName: 'Informaron adicional dirección',
        type: 'text',
        stile:"col-md-6"
    },
    {
        name: 'feasibility',
        displayName: 'VIABILIDAD: Se cuenta con toda la información y recursos requeridos para prestar el servicio solicitado?',
        type: 'select',
        options: ['SI', 'NO'],
        stile:"col-md-8"
    },
    {
        name: 'createQuotation',
        displayName: 'CREAR COTIZACIÓN',
        type: 'button',
        stile:"col-md-4 p-4"
    },
   
     
]
export const ACCORDION_OIN = [
    {
        title: 'ORDEN DE TRABAJO',
        content: [
            {
                name: 'createJob',
                displayName: 'CREAR ORDEN DE TRABAJO',
                type: 'text',
                stile:"col-md-6"
            }, 
            {
                name: 'assignInspector',
                displayName: 'ASIGNAR INSPECTOR',
                type: 'text',
                stile:"col-md-6"
            },
            {
                name: 'sendOt',
                displayName: 'CREAR OT',
                type: 'button',
                stile:"col-md-6"
            },
        ]
    }, 
    {
        title: 'PROCESO DE INSPECCIÓN ',
        content: [
        {
            name: 'securityWord',
            displayName: 'DILIGENCIAR SEGURIDAD EN LA INSPECCIÓN DE EQUIPOS',
            type: 'file',
            stile:"col-md-4",
            filName: "securityWord",
            typeFile: "docx"
        },
        {
            name: 'serviceRecordActa',
            displayName: 'SUBIR ACTA DE SERVICIOS',
            type: 'file',
            stile:"col-md-4",
            filName: "serviceRecordActa",
            typeFile: "docx"
        },
        {
            name: 'inspectionRecordNew',
            displayName: 'REGISTRO DE INSPECCIÓN',
            type: 'file',
            stile:"col-md-4",
            filName: "inspectionRecordNew",
            typeFile: "pdf",
        },
    ]
    }, 
    {
        title: 'REVISION Y TOMA DE DECISION',
        content: [
        {
            name: 'securityWord',
            displayName: 'VER SEGURIDAD EN LA INSPECCIÓN DE EQUIPOS',
            type: 'button',
            stile:"col-md-4 p-3"
        },
        {
            name: 'serviceRecordActa',
            displayName: 'VER ACTA DE SERVICIOS',
            type: 'button',
            stile:"col-md-4 p-3"
        },
        {
            name: 'inspectionRecordNew',
            displayName: 'VER REGISTRO DE INSPECCIÓN',
            type: 'button',
            stile:"col-md-4 p-3"
        },
        {
            name: 'equipmentCorrespondsToOTRequest',
            displayName: 'Equipo corresponde al solicitado en la OT',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'serviceInspectionReportSignedByClient',
            displayName: 'Acta de servicio de inspección diligenciada y firmada por representante del cliente',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'inspectorCompliedWithServiceSchedule',
            displayName: 'Inspector Cumplió con la programación del servicio',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'evidenceOfOpeningMeetingCompletion',
            displayName: 'Evidencia realización de la Reunión de apertura',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'inspectionSafetyFormCompleted',
            displayName: 'Formato seguridad de la inspección diligenciado',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'nonConformitiesDocumentedAndAppropriate',
            displayName: 'Si existen. No conformidades se redactaron y son adecuadas',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'evidenceOfClosingMeetingCompletion',
            displayName: 'Evidencia realización de reunion de cierre',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'reportsCompletenessAndConsistencyWithTypeOfEquipment',
            displayName: 'Informes completos e información coherente con el tipo de equipo',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'elementsOrEquipmentCorrespondToOT',
            displayName: 'Elementos o equipo relacionado corresponde con la OT ',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'elementsOrEquipmentHaveUniqueIdentificationNumbers',
            displayName: 'Elementos o equipo posee numero de identificación únicos ',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'photographicRecordAllowsEvidenceOfInspection',
            displayName: ' Registro fotográfico permite evidenciar inspección del equipo ',
            type: 'select',
            options: ['SI', 'NO', 'N/A'],
            stile:"col-md-4 p-2"
        },
        {
            name: 'decisionOnCertification',
            displayName: 'DECISION DE LA CERTIFICACIÓN ',
            type: 'select',
            options: ['Cumple', 'No cumple'],
            stile:"col-md-6 p-2"
        },
    ]
    }, 
    {
        title: 'GENERAR CERTIFICADO',
        content: [
        {
            name: 'generateCertificate',
            displayName: ' GENERAR CERTIFICADO ',
            type: 'button',
            stile:"col-md-4"
        }, 
        {
            name: 'certificateStatus',
            displayName: 'ESTADO CERTIFICADO',
            type: 'select',
            options: ['EN PROCESO (requisitos pendiente)', 'PENDIENTE DE ENVIÓ', 'ENVIADO'],
            stile:"col-md-4"
        },
        ]
    }, 
     
]
export const TABLE_ORDER_JOB = [
    {
        name: 'id',
        displayName: 'Id',
       
    },
    {
        name: 'name',
        displayName: 'Trabajo',
       
    },
    {
        name: 'inspector',
        displayName: 'Alcance de inspección',
       
    },
    {
        name: 'nameClient',
        displayName: 'Nombre del cliente',
       
    },
    {
        name: 'documentClient',
        displayName: 'Documento del cliente',
       
    },
    {
        name: 'idOin',
        displayName: 'Id Oin',
       
    },
    {
        name: 'state',
        displayName: 'Estado',
       
    },
]

export const HOST_NAME = 'https://izaincol.com.co'
